<div class="filter-container" fxLayout="row" fxLayoutGap="9px">
  <mat-form-field class="address-search-bar" appearance="outline">
    <mat-icon matPrefix class="search-icon">search</mat-icon>
    <mat-label>
      Keyword search
    </mat-label>
    <input matInput type="text" id="QA-BadAddress-SearchInput" [formControl]="filters.get('freetext')">
  </mat-form-field>

  <mat-form-field appearance="outline" id="QA-BadAddress-CityDropDown" fxFlex>
    <mat-label>City</mat-label>
    <mat-select multiple="true" [formControl]="selections.get('city')" id="QA-InvalidAddress-CityDropdown"
      (openedChange)="refreshDropdownOptions($event, 'address.city')">
      <mat-option>
        <ngx-mat-select-search [formControl]="filters.get('city')" noEntriesFoundLabel="No matches"
          placeholderLabel="Find City"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let city of filteredCities | async" [value]="city"  id="QA-InvalidAddress-CityDropdown-Option">
        {{city | titlecase}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" id="QA-BadAddress-ZipDropDown" fxFlex>
    <mat-label>Zip</mat-label>
    <mat-select multiple="true" [formControl]="selections.get('postalCode')" id="QA-InvalidAddress-ZipDropdown"
      (openedChange)="refreshDropdownOptions($event, 'address.postalCode')">
      <mat-option>
        <ngx-mat-select-search [formControl]="filters.get('postalCode')" noEntriesFoundLabel="No matches"
          placeholderLabel="Find Zip Code"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let code of filteredPostalCodes | async" [value]="code">
        {{code}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" id="QA-BadAddress-MappedDown" fxFlex>
    <mat-label>Mapped Status</mat-label>
    <mat-select [formControl]="selections.get('isMapped')">
      <mat-option [value]="false">
        Not Mapped
      </mat-option>
      <mat-option value="true">
        Mapped
      </mat-option>
      <mat-option [value]="">
        Show all
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>