import { Injectable } from '@angular/core';
import { GeocodeCoordinates } from './geocode.model';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {
  constructor() { }

  getGeoLocation(address: string): Promise<GeocodeCoordinates> {
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, ((results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          });
        } else {
          reject(new Error('Error finding the location ' + address));
        }
      }));
    });
  }
}
