import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { firstValueFrom } from 'rxjs';
import {
  ActiveAddress,
  MMessageConfig,
  PostAffectedAddressRequest
} from 'src/app/api/affected-address-api-client.service';
import {
  AddAddressModalComponent
} from '../../../affected-address/affected-addresses-results/add-address-modal/add-address-modal.component';
import {
  AddressDetailsModalComponent
} from '../../../affected-address/affected-addresses-results/address-details-modal/address-details-modal.component';
import { ConfirmationModalCloseResponse, ConfirmationModalComponent } from './confirmation-modal.component';

export interface AddressModalInput {
  addresses: MatTableDataSource<ActiveAddress>;
  address?: ActiveAddress;
  mMessageLevels: MMessageConfig[];
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  constructor(
    private dialog: MatDialog
  ) { }

  openConfirmationModal(
    modalType: string,
    selectedRows: number,
    value: string
  ): Promise<ConfirmationModalCloseResponse> {
    return firstValueFrom(this.dialog.open(ConfirmationModalComponent, {
      width: '570px',
      autoFocus: false,
      disableClose: true,
      data: {
        modalType,
        selectedRows,
        value
      }
    }).afterClosed());
  }

  openAddAddressModal(data: AddressModalInput): Promise<PostAffectedAddressRequest> {
    return firstValueFrom(this.dialog.open(AddAddressModalComponent, {
      height: '700px',
      width: '570px',
      autoFocus: false,
      data
    }).afterClosed());
  }

  openAddressDetailsModal(data: AddressModalInput) {
    this.dialog.open(AddressDetailsModalComponent, {
      height: '825px',
      width: '700px',
      autoFocus: false,
      data
    });
  }
}
