
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { AppConfigService } from '../../app-config/app-config.service';
import { UserSessionService } from './user-session.service';
import { first } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isSignedIn = new BehaviorSubject<boolean>(false);
  public isSignedIn$ = this.isSignedIn.asObservable();

  constructor(
    private appConfigService: AppConfigService,
    private router: Router,
    private userSessionService: UserSessionService,
    private tokenService: TokenService
  ) {
    this.init();
  }

  public async checkSessionStatus() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.tokenService.setTokenValue(user.signInUserSession.idToken.jwtToken);
    } catch (error) {
      await this.signIn();
    }
    this.isSignedIn.next(true);
    this.watchIdleState();
  }

  public async signIn(): Promise<void> {
    Auth
      .federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito })
      .catch(e => console.log(e));
  }

  public async signOut(): Promise<void> {
    await Auth.signOut();
    this.isSignedIn.next(false);
    await this.router.navigate(['/logout']);
  }

  private init(): void {
    let domainUrl: string;

    if (this.appConfigService.config.environmentName === 'prod') {
      // set redirect urls for prod
      domainUrl = 'auth.portal.psps714.com';
    } else {
      // set redirect urls for nonprod
      domainUrl = 'auth.'.concat(this.appConfigService.config.environmentName, '.portal.psps714.com');
    }

    Auth.configure({
      region: this.appConfigService.config.region,
      userPoolId: this.appConfigService.config.userPoolId,
      userPoolWebClientId: this.appConfigService.config.userPoolWebClientId,
      mandatorySignIn: true,
      oauth: {
        domain: domainUrl,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: `${window.location.origin}/home`,
        redirectSignOut: `${window.location.origin}/logout`,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        client_id: this.appConfigService.config.userPoolWebClientId
      }
    });

    this.checkSessionStatus();
  }

  watchIdleState() {
    this.userSessionService.startWatching();
    this.userSessionService.onTimerStart().pipe(first()).subscribe(async () => {
      this.userSessionService.setIdleLogoutState(true);
      this.userSessionService.stopWatching();
      await this.signOut();
    });
  }
}