import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';

import { LoginComponent } from './login/login.component';

import { AppConfigService } from './app-config/app-config.service';
import {
  CommunityCenterApiClientService,
  API_BASE_URL as COMMUNITY_CENTER_API_BASE_URL
} from './api/community-center-api-client.service';
import { AppConfig, APP_CONFIG } from './app-config/app-config.model';
import {
  AffectedAddressApiClientService,
  API_BASE_URL as AFFECTED_ADDRESS_API_BASE_URL
} from './api/affected-address-api-client.service';
import {
  NotificationApiClientService,
  API_BASE_URL as NOTIFICATION_API_BASE_URL
} from './api/notification-api-client.service';
import {
  API_BASE_URL as APP_CONFIG_API_BASE_URL,
  AppConfigApiClientService
} from './api/app-config-api-client.service';
import {
  IngestionMonitorApiClientService,
  API_BASE_URL as INGESTION_MONITOR_API_BASE_URL
} from './api/ingestion-monitor-api-client.service';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { DataManagementModule } from './data-management/data-management.module';
import { LogoutComponent } from './logout/logout.component';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import { TokenService } from './core/services/token.service';
import { RulesAndSettingsModule } from './rules-and-settings/rules-and-settings.module';

function initializeApp(
  appConfigService: AppConfigService
) {
  return async () => {
    await appConfigService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    AngularMaterialModule,
    DataManagementModule,
    RulesAndSettingsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: APP_CONFIG,
      useFactory: (appConfigService: AppConfigService) => {
        return appConfigService.config;
      },
      deps: [AppConfigService]
    },
    {
      provide: COMMUNITY_CENTER_API_BASE_URL,
      useFactory: (appConfig: AppConfig) => {
        return appConfig.communityCenterServiceEndpoint;
      },
      deps: [APP_CONFIG]
    },
    CommunityCenterApiClientService,
    {
      provide: AFFECTED_ADDRESS_API_BASE_URL,
      useFactory: (appConfig: AppConfig) => {
        return appConfig.affectedAddressServiceEndpoint;
      },
      deps: [APP_CONFIG]
    },
    AffectedAddressApiClientService,
    {
      provide: APP_CONFIG_API_BASE_URL,
      useFactory: (appConfig: AppConfig) => {
        return appConfig.appConfigServiceEndpoint;
      },
      deps: [APP_CONFIG]
    },
    AppConfigApiClientService,
    {
      provide: NOTIFICATION_API_BASE_URL,
      useFactory: (appConfig: AppConfig) => {
        return appConfig.notificationServiceEndpoint;
      },
      deps: [APP_CONFIG]
    },
    NotificationApiClientService,
    {
      provide: INGESTION_MONITOR_API_BASE_URL,
      useFactory: (appConfig: AppConfig) => {
        return appConfig.ingestionMonitorServiceEndpoint;
      },
      deps: [APP_CONFIG]
    },
    IngestionMonitorApiClientService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    TokenService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
