export enum Languages {
  English = 'en',
  Spanish = 'es'
}

export enum LanguageNames {
  en = 'English',
  es = 'Spanish'
}

export const DefaultLanguage = Languages.English;

