<div id="QA-Blocked-Address-AddAddressModalContainer" [formGroup]="addAddressForm" class="add-field">
    <div mat-dialog-content>
      <div class="right-align">
        <button mat-button [mat-dialog-close]="true" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="header-row">
        <span id="QA-Add-Address-Title" class="address-header">Add New Address</span>
      </div>
      <div id="QA-BlockedAddress-Modal-GoogleMap" #blockedAddressMapContainer class="map">
      </div>
      <div class="row">
        <div style="padding-top: 20px; padding-right: 40px;" class="col">
          <app-search-google-autocomplete
            #autocomplete
            appearance="outline"
            label="Street Address"
            floatLabel="auto"
            class="full-length"
            (placeSelected)="onPlaceSelected($event)"
            required="true"
          ></app-search-google-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" class="full-length">
                <mat-label>Blocked Permanently</mat-label>
                <mat-select id="QA-addAddress-Modal-BlockedPermanentlyDropdown" formControlName="blockedPermanently" required>
                    <mat-option *ngFor="let option of blockPermanentlyOptions" [value]="option.value" id="QA-BlockPermanently-DropDown-Option">
                      {{ option.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="addAddressForm.get('blockedPermanently').hasError('required')">This field is required.</mat-error>
            </mat-form-field>
        </div>
        <mat-icon color="primary" class="help" matTooltip="{{ blockedPermanentlyTooltip }}" matTooltipPosition="above"
            matTooltipClass="add-address-tooltip">help_outline</mat-icon>
      </div>
      <mat-error *ngIf="!activeEvent && addAddressForm.get('blockedPermanently').value===false">There is no active PSPS event, the 'Block Permanently?' toggle must be enabled.</mat-error>
    </div>
    <div>
        <mat-dialog-actions align="end">
          <button id="QA-Add-Address-Modal-CancelButton" mat-flat-button color="outline" class="address-dialog-actions" [mat-dialog-close]="true">Cancel</button>
          <button id="QA-Add-Address-Modal-SaveButton" mat-flat-button color="primary" class="address-dialog-actions" (click)="validateNewAddress()">Add</button>
        </mat-dialog-actions>
      </div>
</div>