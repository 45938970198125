import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { addressComponentNames } from 'src/app/shared/constants/place-details';

@Component({
  selector: 'app-replace-address-modal',
  templateUrl: './replace-address-modal.component.html',
  styleUrls: ['./replace-address-modal.component.css']
})
export class ReplaceAddressModalComponent implements OnInit, AfterViewInit {
  @ViewChild('replaceAddressMapContainer', { static: false }) gMapContainer: ElementRef;
  public badAddressValue = '';
  public isMultiple = false;
  isValidAddress = true;

  private map: google.maps.Map;
  private marker: google.maps.Marker;

  private lat: number;
  private lng: number;

  placeDetails: google.maps.places.PlaceResult;
  placeId: string;
  constructor(
    public dialogRef: MatDialogRef<ReplaceAddressModalComponent>,
    public geocodeService: GeocodeService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.isMultiple = this.data.replaceMultiple;
    this.badAddressValue = this.isMultiple ? 'Multiple' :
      `${this.data.address}, ${this.data.city}, ${this.data.state} ${this.data.postalCode}`;
  }

  async ngAfterViewInit() {
    // Default map view will be centered on San Diego
    const geolocation = await this.geocodeService.getGeoLocation('San Diego, California');
    this.lat = geolocation.lat;
    this.lng = geolocation.lng;

    const coords = new google.maps.LatLng(this.lat, this.lng);
    this.map = new google.maps.Map(this.gMapContainer.nativeElement, {
      center: coords,
      zoom: 10,
      disableDefaultUI: true,
      zoomControl: true
    });
  }

  onPlaceSelected(placeDetails: google.maps.places.PlaceResult) {
    if (this.checkIfValidAddress(placeDetails)) {
      const lat = placeDetails.geometry.location.lat();
      const lng = placeDetails.geometry.location.lng();
      this.placeDetails = placeDetails;
      this.placeId = placeDetails.place_id;

      if (this.marker) {
        this.marker.setMap(null);
      }
      this.marker = this.createMarker(lat, lng);
    }
  }

  checkIfValidAddress(placeDetails: google.maps.places.PlaceResult): boolean {
    for (const componentKey of addressComponentNames) {
      const types = [].concat(...placeDetails.address_components.map(i => i.types));
      if (!types.includes(componentKey)) {
        this.isValidAddress = false;
        return false;
      }
    }
    this.isValidAddress = true;
    return true;
  }

  replaceAddress() {
    if (this.placeDetails && this.placeId && this.isValidAddress) {
      this.dialogRef.close({ googleApiResponse: this.placeDetails, googlePlaceId: this.placeId, id: this.data.id });
    } else {
      this.isValidAddress = false;
    }
  }

  /**
   * Applies the pin to the map at the location's address.
   */
  private createMarker(lat: number, lng: number): google.maps.Marker {
    const coords = new google.maps.LatLng(lat, lng);
    const marker = new google.maps.Marker({
      map: this.map,
      position: coords,
      icon: '/assets/icons/map-pin.svg'
    });
    marker.setMap(this.map);
    if (marker.getPosition()) {
      this.map.setCenter(coords);
    }
    return marker;
  }
}
