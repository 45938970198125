<div style="margin-top: -10px">
  <div class="right-align">
    <button mat-button [mat-dialog-close]="true" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="header-row">
    <span class="address-header">Replace Bad {{ isMultiple ? 'Addresses' : 'Address' }}</span>
  </div>
  <div>
    <span style="font-size: 14px">Replace the current bad {{ isMultiple ? 'addresses' : 'address' }} with one recognized
      by the Google API</span>
  </div>
  <div #replaceAddressMapContainer class="map">
  </div>
  <div>
    <app-search-google-autocomplete appearance="outline" label="Street Address" floatLabel="auto"
      (placeSelected)="onPlaceSelected($event)" class="full-length" required="true" [externalInvalid]="!isValidAddress"
      externalValidationError="This address is invalid and cannot be used">
    </app-search-google-autocomplete>
  </div>
  <div>
    <mat-form-field appearance="outline" class="full-length">
      <mat-label>Bad Address</mat-label>
      <input matInput disabled [value]="badAddressValue">
    </mat-form-field>
  </div>
  <div style="padding-top: 15px;">
    <mat-dialog-actions align="end">
      <button mat-flat-button color="outline" class="address-dialog-actions" [mat-dialog-close]="true">Cancel</button>
      <button mat-flat-button color="primary" class="address-dialog-actions" (click)="replaceAddress()">Replace</button>
    </mat-dialog-actions>
  </div>
</div>