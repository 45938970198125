import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'ertSource'})
export class ErtSourcePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'outage':
        return 'Outage.JSON';
      case 'portal':
        return  'Portal Override';
      default:
        return value;
    }
  }
}
