import { ActiveAddress, AdHocNotification, AdHocNotificationPostResponse, BulkOperationResponse } from '../../api/affected-address-api-client.service';
import {
  ActiveAddressesResponse,
  PatchAffectedAddressItem,
  ActiveAddressSortKey
} from '../../api/affected-address-api-client.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { AffectedAddressService } from 'src/app/core/services/affected-address.service';
import { ProgressBarComponent } from 'src/app/shared/components/progress-bar/progress-bar.component';
import { SnackBarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { firstValueFrom } from 'rxjs';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { ConfirmationModalType } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';

export enum BulkUpdateType {
  Block = 'block',
  Deactivate = 'isActive',
  EstimatedRestorationTime = 'estimatedRestorationTime',
  StatusSource = 'statusSource',
  SendAdHocNotification = 'sendAdHocNotification'
}
@Injectable({
  providedIn: 'root'
})
export class AffectedAddressesBulkService {
  public progressBarModal: MatDialogRef<ProgressBarComponent>;
  private isNotificationEnabled: boolean;
  private bulkSortKey: ActiveAddressSortKey;
  private totalSelectedRows: number;
  public itemsWithErrors = 0;
  public itemsProcessed = 0;

  constructor(
    private affectedAddressService: AffectedAddressService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private confirmationModalService: ConfirmationModalService
  ) {}

  // create and launch progress bar window
  public showProgressBar(totalItems: number): MatDialogRef<ProgressBarComponent> {
    this.itemsProcessed = 0;
    this.itemsWithErrors = 0;
    return this.dialog.open(ProgressBarComponent, {
      height: '150px',
      width: '500px',
      data: {
        totalItems
      },
      autoFocus: false,
      disableClose: true
    });
  }

  // get the active addresses on each page for processing
  async getPageAddresses(
    searchCriteria: any,
    pageSize: number,
    idOnly: boolean
  ): Promise<ActiveAddressesResponse> {
    return await firstValueFrom(this.affectedAddressService.getActiveAddressesByQuery(
      {
        ...searchCriteria,
        pageSize,
        searchAfter: this.bulkSortKey?.last,
        idOnly
      }
    ));
  }

  async getPageAndPatch(
    currentAddressQuery: any,
    property: BulkUpdateType,
    value: string,
    selection: SelectionModel<ActiveAddress>,
    totalSelectedRows: number,
    isNotificationEnabled: boolean = false,
    adHocNotification?: AdHocNotification[]
  ): Promise<void> {
    let isAdHoc = false;

    // if send ad hoc, show "request sent" modal
    if ( adHocNotification ) {
      isAdHoc = true;
      this.confirmationModalService.openConfirmationModal(
        ConfirmationModalType.adHocNotificationRequestSent,
        null,
        null
      );
    } else { // else, show progress bar for bulk update requests
      this.progressBarModal = this.showProgressBar(totalSelectedRows);
    }

    // Assign total addresses being acted on
    this.totalSelectedRows = totalSelectedRows;
    // Assign notification flag
    this.isNotificationEnabled = isNotificationEnabled;
    let selectedRows = 0;
    if (selection?.selected)
      selectedRows = selection.selected.length;

    if (selectedRows === this.totalSelectedRows) {
      try {
        // if only addresses on current page are selected, perform single page update
        await this.handleSinglePageUpdate(selection.selected, property, value, adHocNotification);
      } catch (err) {
        this.snackBarService.showErrorSnackBar();
        if (this.progressBarModal) {
          this.progressBarModal.close();
        }
        return Promise.reject();
      }
    } else {
      // if multiple pages of addresses are selected, perform multi page update
      const maxPageSize = 250;
      // reset bulk sort key and fetch all addresses within search criteria
      this.bulkSortKey = null;

      for (let i = 0; i <= (this.totalSelectedRows / maxPageSize); i++) {
        try {
          const pageResponse = await this.getPageAddresses(currentAddressQuery, maxPageSize, true);
          // get the next page sort key
          this.bulkSortKey = pageResponse.result.sortKey;
          await this.handleSinglePageUpdate(pageResponse.result.addresses, property, value, adHocNotification);
        } catch (err) {
          // Show an error message and stop the pagination
          this.snackBarService.showErrorSnackBar();

          if (this.progressBarModal) {
            this.progressBarModal.close();
          }

          return Promise.reject();
        }
      }
    }
    return this.returnCompletionResults(isAdHoc);
  }

  async handleSinglePageUpdate(
    addresses: ActiveAddress[],
    property: BulkUpdateType,
    value: string,
    adHocNotification?: AdHocNotification[]
  ): Promise<void> {
    const array = this.constructPatchRequestArray(addresses, property, value);

    try {
      const patchResponse = await this.performUpdate(array, property, adHocNotification);

      this.itemsProcessed += patchResponse.result.itemsProcessed;
      this.itemsWithErrors += patchResponse.result.itemsWithErrors;

      if ( !adHocNotification ) {
        this.progressBarModal.componentInstance.updateProgressValues(this.itemsProcessed);
      }
    } catch (err) {
      // Show an error message and throw a new error to stop the pagination
      this.snackBarService.showErrorSnackBar();
      return Promise.reject();
    }
  }

  constructPatchRequestArray(
    addresses: ActiveAddress[],
    property: BulkUpdateType,
    value?: string
  ): (string | PatchAffectedAddressItem)[] {
    // if only items on the first page are selected, no need to make api call
    // selection should contain all addresses needed
    if (property === BulkUpdateType.Block) {
      const addressesToBlock: Set<string> = new Set();
      addresses.forEach(address => {
        addressesToBlock.add(address.addressKey);
      });
      return Array.from(addressesToBlock);
    } else if (property === BulkUpdateType.SendAdHocNotification) {
      const addressesToSend: Set<string> = new Set();
      addresses.forEach(address => {
        addressesToSend.add(address.addressKey);
      });
      return Array.from(addressesToSend);
    } else {
      const addressesToUpdate: PatchAffectedAddressItem[] = [];
      addresses.forEach(address => {
        addressesToUpdate.push({
          property,
          value,
          id: address.id
        });
      });
      return addressesToUpdate;
    }
  }

  async performUpdate(addresses: any[], property: BulkUpdateType, adHocNotification?: AdHocNotification[]): Promise<BulkOperationResponse | AdHocNotificationPostResponse> { //
    if (property === BulkUpdateType.Block) {
      return await firstValueFrom(this.affectedAddressService
        .blockAddresses(addresses));
    } else if (property === BulkUpdateType.SendAdHocNotification) {
      return await firstValueFrom(this.affectedAddressService.sendAdHocNotification({
        addressKeys: addresses,
        adHocNotification
      }));
    } else {
      return await firstValueFrom(this.affectedAddressService
        .patchAffectedAddresses(addresses, !this.isNotificationEnabled));
    }
  }

  returnCompletionResults(isAdHoc: boolean): Promise<void> {
    if (this.itemsWithErrors === 0) {
      if (!isAdHoc) this.progressBarModal.close();
      this.snackBarService.showSuccessSnackBar();
      return Promise.resolve();
    } else {
      if (!isAdHoc) this.progressBarModal.close();
      const errMsg = `[${this.itemsProcessed}] records successful, [${this.itemsWithErrors}] records failed`;
      this.snackBarService.showErrorSnackBar(errMsg);
      return Promise.reject();
    }
  }
}
