import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { SearchState, SearchViewModelService } from './search-view-model.service';

@Component({
  selector: 'app-search-google-autocomplete',
  templateUrl: './search-google-autocomplete.component.html',
  styleUrls: ['./search-google-autocomplete.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: SearchGoogleAutocompleteComponent
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: SearchGoogleAutocompleteComponent
  }]
})
export class SearchGoogleAutocompleteComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @Input() streetName: string;
  @Input() appearance: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() floatLabel: string;
  @Input() externalInvalid: boolean;
  @Input() externalValidationError: string;
  @Input() required: boolean;
  @Input() enforceSelectionBySearchResults: boolean;
  @Output() placeSelected = new EventEmitter<google.maps.places.PlaceResult>();
  @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;

  parentOnChangeCallback = (value) => {};
  parentOnTouchedCallback = () => {};
  touched: boolean = false;
  inputControlDisabled: boolean = false;

  vm$: Observable<SearchState> = this.searchVMService.vm$;
  searchTerm: string;

  public floatLabelControl: FormControl;
  public inputControl = new FormControl();

  private currentTermSelectedFromSearchResultsOrInitialized: boolean = true;
  private currentTerm: string = '';

  constructor(private searchVMService: SearchViewModelService) { }

  ngOnInit(): void {
    if (this.streetName) {
      this.searchTerm = this.streetName;
    }
    this.floatLabelControl = new FormControl(this.floatLabel);
    window.addEventListener('scroll', this.scrollEvent, true);

    if(this.enforceSelectionBySearchResults) {
      this.inputControl.addValidators(this.userSelectedSearchResultValidator());
    }
  }

  ngOnChanges() {
    this.inputControl.setErrors({invalidExternally: this.externalInvalid});
  }

  ngAfterViewInit() {
    this.inputControl.valueChanges.subscribe((value) => {
      this.parentOnChangeCallback(value);

      if (!this.touched) {
        this.touched = true;
        this.parentOnTouchedCallback();
      }
    });
  }


  updateSearchResults(): void {
    this.searchVMService.updateSearchTerm(this.searchTerm);
  }

  async selectSearchResult(place: google.maps.places.AutocompletePrediction): Promise<void> {
    this.searchVMService.getPlaceDetails(place.place_id).subscribe(async details => {
      this.currentTerm = this.searchTerm;
      this.currentTermSelectedFromSearchResultsOrInitialized = true;
      this.inputControl.setValue(this.currentTerm);

      this.placeSelected.emit(details);
      this.searchVMService.clear();
    });
  }

  scrollEvent = (event: any): void => {
    if (this.autoComplete.panelOpen) {
      this.autoComplete.updatePosition();
    }
  }

  markAsTouched() {
    this.touched = true;
    this.parentOnTouchedCallback();
    this.inputControl.markAsTouched();
  }

  valid(): boolean {
    return this.inputControl.valid;
  }

  // enforces that the value in the input control was selected from the search results
  userSelectedSearchResultValidator() {
    return (control: AbstractControl): ValidationErrors | null => {

      // if user had selected from the search results but then manually edited the value...
      if (this.currentTermSelectedFromSearchResultsOrInitialized && (control.value !== this.currentTerm)) {
        this.currentTermSelectedFromSearchResultsOrInitialized = false;
      }

      // pristine with value case occurs when the modal is opened with prepopulated values
      // in which case we consider it valid
      if (!this.inputControl?.pristine && (control.value != null)) {
        return !this.currentTermSelectedFromSearchResultsOrInitialized ? { searchResultSelected: { value: false } } : null;
      }

      return null;
    };
  }

  // custom form control interface functions implementing ControlValueAccessor and its validation function
  // note that as of this writing most parent components using this have not been transitioned to using this as one

  // value provided when parent sets the value
  writeValue(input: string) {
    this.currentTermSelectedFromSearchResultsOrInitialized = true;
    this.searchTerm = input;
  }

  registerOnChange(parentOnChangeCallback: any) {
    this.parentOnChangeCallback = parentOnChangeCallback;
  }

  registerOnTouched(parentOnTouchedCallback: any) {
    this.parentOnTouchedCallback = parentOnTouchedCallback;
  }

  setDisabledState(disable: boolean) {
    if (disable) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.userSelectedSearchResultValidator()(control);
  }
}
