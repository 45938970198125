import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<AppConfig>('app config');

export interface AppConfig {
  /**
   * API URL for the Notification Service.
   */
  notificationServiceEndpoint: string;

  /**
   * API URL for the Affected Address Service.
   */
  affectedAddressServiceEndpoint: string;

  /**
   * API URL for the Community Center Service.
   */
  communityCenterServiceEndpoint: string;

  /**
   * API URL for the App Config Service
   */
  appConfigServiceEndpoint: string;

  /**
   * API URL for the Ingestion Monitor Service
   */
  ingestionMonitorServiceEndpoint: string;

  /**
   * Cognito User Pool ID.
   */
  userPoolId: string;

  /**
   * Cognito App Web Client ID.
   */
  userPoolWebClientId: string;

  /**
   * Cognito Identity Pool ID.
   */
  identityPoolId: string;

  /**
   * Environment name.
   */
  environmentName: string;

  /**
   * Number of seconds before an idle user's session ends.
   */
  idleSessionTimeout: number;

  /**
   * AWS Region for backend resources.
   */
   region: string;
}
