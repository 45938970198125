import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FileSourcePipe } from './pipes/file-source.pipe';
import { ErtSourcePipe } from './pipes/ert-source.pipe';
import { SearchGoogleAutocompleteComponent } from './components/search-google-autocomplete/search-google-autocomplete.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { StatusNamePipe } from './pipes/status-name.pipe';
import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { FullAddressPipe } from './pipes/full-address.pipe';

@NgModule({
  declarations: [
    FileSourcePipe,
    ErtSourcePipe,
    SearchGoogleAutocompleteComponent,
    SnackBarComponent,
    StatusNamePipe,
    FullAddressPipe
  ],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  providers: [
    FileSourcePipe,
    ErtSourcePipe,
    StatusNamePipe,
    DatePipe,
    FullAddressPipe
  ],
  exports: [
    FileSourcePipe,
    ErtSourcePipe,
    StatusNamePipe,
    FullAddressPipe,
    SearchGoogleAutocompleteComponent,
    SnackBarComponent,
    AngularMaterialModule
  ]
})
export class SharedModule { }
