<div #dropdownContainer>
  <div *ngIf="(vm$ | async) as vm">
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabelControl.value" class="full-length">
      <mat-label>{{ label }}</mat-label>
      <input matInput
      #autocompleteInput
      [(ngModel)]="searchTerm"
      (keyup)="updateSearchResults()"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      [required]="required"
      [formControl]="inputControl"
      id="QA-Autocomplete-AddressInput">
      <mat-error *ngIf="inputControl.hasError('required')">This field is required.</mat-error>
      <mat-error *ngIf="inputControl.hasError('invalidExternally')">{{ externalValidationError }}</mat-error>
      <mat-error *ngIf="inputControl.hasError('searchResultSelected')">Select the address from the search results dropdown.</mat-error>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let item of vm.searchResults"
        [value]="item.description"
        (click)="selectSearchResult(item)">
        {{ item.description }}
      <div class="divider"></div>
      </mat-option>
      <p>
        <img src="assets/icons/powered_by_google_on_white.svg" class="logo"/>
      </p>
    </mat-autocomplete>
  </div>
</div>
