import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { MapResolver } from './shared/resolvers/map.resolver';
import { BadAddressesComponent } from './bad-address/bad-address.component';
import { LogoutComponent } from './logout/logout.component';
import { BlockedAddressesComponent } from './blocked-address/blocked-address.component';
import { MmessageComponent } from './mmessage/mmessage.component';
import { PushNotificationContentComponent } from './push-notification-content/push-notification-content.component';
import { DataOverrideMatrixComponent } from './data-override-matrix/data-override-matrix.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./home/home.module').then(
        m => m.HomeModule
      )
  },
  {
    path: 'community-centers',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./community-center/community-center.module').then(
        m => m.CommunityCenterModule
      ),
    resolve: {
      mapApiKey: MapResolver
    }
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(
        m => m.DashboardModule
      )
  },
  {
    path: 'affected-addresses',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./affected-address/affected-addresses.module').then(
        m => m.AffectedAddressesModule
      ),
    resolve: {
      mapApiKey: MapResolver
    }
  },
  {
    path: 'bad-addresses',
    component: BadAddressesComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./bad-address/bad-address.module').then(
        m => m.BadAddressModule
      ),
    resolve: {
      mapApiKey: MapResolver
    }
  },
  {
    path: 'blocked-address',
    component: BlockedAddressesComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./blocked-address/blocked-address.module').then(
        m => m.BlockedAddressModule
      ),
      resolve: {
        mapApiKey: MapResolver
      }
  },
  {
    path: 'mmessage',
    component: MmessageComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./mmessage/mmessage.module').then(
        m => m.MmessageModule
      )
  },
  {
    path: 'push-notification-content',
    component: PushNotificationContentComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./push-notification-content/push-notification-content.module').then(
        m => m.PushNotificationContentModule
      )
  },
  {
    path: 'map-configuration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./map-configuration/map-configuration.module').then(
        m => m.MapConfigurationModule
      ),
    resolve: {
      mapApiKey: MapResolver
    }
  },
  {
    path: 'data-override-matrix',
    component: DataOverrideMatrixComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./data-override-matrix/data-override-matrix.module').then(
        m => m.DataOverrideMatrixModule
      )
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  { 
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
