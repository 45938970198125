<div id="QA-PushNotificationSettingsPageContainer" class="container">
    <ng-container>
      <div class="manage-header">
        <h6 id="QA-PushNotification-Title">
          Push Notification Settings
        </h6>
        <ng-container matColumnDef="AddPushNotification">
          <span>
            <button id="QA-PushNotification-AddPushNotificationButton" mat-button color="primary" class="add-button"
            (click)="openAddModal()">+ Add Push Notification</button>
          </span>
        </ng-container>
      </div>

      <table mat-table id="pushNotificationContent" [dataSource]="pushNotificationContent">

        <ng-container matColumnDef="Id">
          <th id="QA-PushNotification-Row-IdHeader" mat-header-cell *matHeaderCellDef> Notification Type </th>
          <td id="QA-PushNotification-Row-Id" mat-cell *matCellDef="let element"> {{ element[mainLanguage]?.id || '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="Title">
          <th id="QA-PushNotification-Row-TitleHeader" mat-header-cell *matHeaderCellDef> Title </th>
          <td id="QA-PushNotification-Row-Title" mat-cell *matCellDef="let element"> {{ element[mainLanguage]?.title || '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="NotificationsEnabled">
          <th id="QA-PushNotification-Row-NotificationsEnabledHeader" mat-header-cell *matHeaderCellDef> Notifications Enabled </th>
          <td id="QA-PushNotification-Row-NotificationsEnabled" mat-cell *matCellDef="let element"> {{ element[mainLanguage]?.sendPushNotification ?? false }} </td>
        </ng-container>

        <ng-container matColumnDef="Body">
          <th id="QA-PushNotification-Row-BodyHeader" mat-header-cell *matHeaderCellDef> Body </th>
          <td id="QA-PushNotification-Row-Body" mat-cell *matCellDef="let element"> {{ element[mainLanguage]?.body || '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="LastUpdated">
          <th id="QA-PushNotification-Row-LastUpdatedHeader" mat-header-cell *matHeaderCellDef> Last Updated </th>
          <td id="QA-PushNotification-Row-LastUpdated" mat-cell *matCellDef="let element"> {{ element[mainLanguage]?.updatedOn * 1000 | date: "M/d/yyyy \'at\' h:mm a" }} </td>
        </ng-container>

        <ng-container matColumnDef="Languages">
          <th mat-header-cell *matHeaderCellDef> Languages </th>
          <td mat-cell *matCellDef="let element" id="QA-PushNotification-Row-Language">
            {{ displayLanguagesForRow(element) }}
            <mat-icon *ngIf="displayMissingLanguageTooltip(element)" color="primary" class="help" matTooltip="{{ missingLanguageTooltipMessage }}" matTooltipPosition="above" id="QA-PushNotification-Row-Language-Missing-Icon">help_outline</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-flat-button color="white" (click)="openEditModal(element)" id="QA-Mmessage-Row-Edit">Edit</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" id="QA-PushNotification-Row"></tr>
      </table>
    </ng-container>

    <div *ngIf="isLoading" class="spinner-container">
      <div class="spinner-text">
        This search may take a minute...
        <div>
          <mat-progress-spinner diameter="23" color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>
