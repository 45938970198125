import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    public router: Router,
    private tokenService: TokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.tokenService.token$.pipe(
      switchMap(token => {
        if (request.url.includes('api.psps714')) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 401:
                this.router.navigate(['/']);
                break;
              case 403:
                this.router.navigate(['/']);
                break;
            }
            return throwError(() => error);
          })
        );
      })
    );
  }
}