import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PushNotificationContent } from '../api/notification-api-client.service';
import { catchError, EMPTY, map, Subscription } from 'rxjs';
import { SnackBarService } from '../shared/components/snackbar/snackbar.service';
import { NotificationService } from '../core/services/notification.service';
import { EditPushNotificationContentComponent } from './edit-push-notification-content-modal/edit-push-notification-content-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AddPushNotificationContentComponent } from './add-push-notification-content-modal/add-push-notification-content-modal.component';
import { ActivatedRoute } from '@angular/router';
import { DefaultLanguage, LanguageNames, Languages } from '../shared/constants/language';
import { PushNotificationContentByLanguage } from './models/push-notification-content-by-language.model';

@Component({
  selector: 'app-push-notification-content',
  templateUrl: './push-notification-content.component.html',
  styleUrls: ['./push-notification-content.component.css']
})
export class PushNotificationContentComponent implements OnInit, OnDestroy {
  public pushNotificationContent = new MatTableDataSource<PushNotificationContentByLanguage>();
  public isLoading = true;
  public id: string = "";

  public pushNotificationIds: string[];

  public displayedColumns = [
    'Id',
    'Title',
    'NotificationsEnabled',
    'Body',
    'LastUpdated',
    'Languages',
    'Edit'
  ];

  public missingLanguageTooltipMessage = 'Not all available languages have been configured';

  public mainLanguage = DefaultLanguage;

  private subscription: Subscription;

  constructor(
    private notificationService: NotificationService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.id = params.id;
        this.getPushNotificationContents();
      }
    );
  }

  getPushNotificationContents(): void {
    this.subscription = this.notificationService.getAllPushNotificationContent().pipe(
      catchError(e => {
        this.snackBarService.showErrorSnackBar(e.message);
        console.error(e);
        this.pushNotificationContent.data = [];
        this.pushNotificationIds = [];
        this.isLoading = false;
        return EMPTY;
      }),
      map(res => res.result)
    ).subscribe( res => {
      this.pushNotificationContent.data = this.getOrderedTableData(res);

      this.pushNotificationIds = res.filter(content => content.id.startsWith("M")).map(result => result.id);
      this.pushNotificationIds.sort((a, b) => this.parseMId(a) - this.parseMId(b) || a.charCodeAt(a.length-1) - b.charCodeAt(b.length-1));

      this.isLoading = false;

      if (this.id) {
        this.openAddModal();
      }
    });
  }

  getOrderedTableData(res: PushNotificationContent[]): PushNotificationContentByLanguage[] {
    const data = res.filter(item => item.id !== 'All'); // filter out 'All' notification setting row and non-English data rows

    const dataByLanguage = {};

    for (let dataItem of data) {
      if (!dataByLanguage[dataItem.id]) {
        dataByLanguage[dataItem.id] = {};
      }

      dataByLanguage[dataItem.id][dataItem.language] = dataItem;
    }

    const idKeys = Object.keys(dataByLanguage);
    idKeys.sort((a, b) => this.parseMId(a) - this.parseMId(b) || a.charCodeAt(a.length-1) - b.charCodeAt(b.length-1))

    const tableData = [];

    for (let idKey of idKeys) {
      tableData.push(dataByLanguage[idKey]);
    }

    return tableData;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openEditModal(pushNotificationContentByLanguage: PushNotificationContentByLanguage) {
    this.dialog.open(EditPushNotificationContentComponent, {
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: {
        pushNotificationContentByLanguage,
        refresh: () => this.getPushNotificationContents()
      }
    });
  }

  parseMId(id: string): number {
    try {
      return parseInt(id.match(/\d+/)[0]);
    } catch {
      return id.charCodeAt(0) // sort text only status (AssessingOutage/PowerRestored/etc) alphabetically below M-Message codes
    }
  }

  openAddModal() {
    const ids = this.pushNotificationIds;

    this.dialog.open(AddPushNotificationContentComponent, {
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: {
        ids,
        pushNotificationContent: {id: this.id},
        refresh: () => this.getPushNotificationContents()
      }
    });
  }

  displayLanguagesForRow(pushNotificationContentByLanguage: PushNotificationContentByLanguage) {
    const languagesOnElement = Object.keys(pushNotificationContentByLanguage);

    return languagesOnElement.map(x => LanguageNames[x]).join(', ');
  }

  displayMissingLanguageTooltip(pushNotificationContentByLanguage: PushNotificationContentByLanguage) {
    const languagesOnElement = Object.keys(pushNotificationContentByLanguage);
    const languageNames = Object.keys(LanguageNames);

    return languagesOnElement?.length !== languageNames?.length;
  }
}
