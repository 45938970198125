<div>
  <div class="header-container">
    <p><b class="header-text">Updating, please wait</b></p>
  </div>
</div>
<div class="records-count-container">
  <p class="records-count-text">{{ itemsProcessed }} out of {{ totalItems }} records updated</p>
</div>
<div style="position: relative">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>