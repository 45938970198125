import { Component } from '@angular/core';
import { UserSessionService } from '../core/services/user-session.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

  public isIdleLogout$ = this.userSessionService.getIdleLogoutState();

  constructor(
    private userSessionService: UserSessionService) { }
}
