import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  progress: number = 0;
  itemsProcessed: number = 0;
  totalItems: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ProgressBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { totalItems: number }
  ) { }

  ngOnInit(): void {
    this.totalItems = this.data.totalItems;
  }

  updateProgressValues(itemsProcessed: number): void {
    this.itemsProcessed = itemsProcessed;
    this.progress = (this.itemsProcessed / this.totalItems) * 100;
  }
}
