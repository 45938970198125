import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AffectedAddressApiClientService } from 'src/app/api/affected-address-api-client.service';
import { AppConfigApiClientService } from 'src/app/api/app-config-api-client.service';
import { MapService } from 'src/app/core/services/map.service';


@Injectable({ providedIn: 'root' })

export class MapResolver implements Resolve<Observable<string>> {

  constructor(
    private appConfigApiService: AppConfigApiClientService,
    private mapService: MapService,
    private affectedAdddressService: AffectedAddressApiClientService
  ) { }

  resolve(): Observable<string> {
    if (this.mapService.loaded && this.mapService.apiKey !== '') {
      return of(this.mapService.apiKey);
    }

    return forkJoin([
      this.appConfigApiService.getGoogleApiKey().pipe(
        take(1),
        map(keyResult => keyResult.result),
        switchMap(key => this.mapService.load(key))
      ),
      this.affectedAdddressService.getServiceArea().pipe(
        take(1),
        map(serviceAreaResult => serviceAreaResult.result.bounds),
        tap(serviceArea => this.mapService.serviceArea = serviceArea)
      )
    ]).pipe(map(_ => this.mapService.apiKey));
  }
}
