import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

export interface RowData {
    currentState: string;

    PotentialOutage: string;
    PotentialOutageToolTip: string;

    PSPSOutage: string;
    PSPSOutageToolTip: string;

    OnPatrol: string;
    OnPatrolToolTip: string;

    PowerRestored: string;
    PowerRestoredToolTip: string;

    NoPSPS: string;
    NoPSPSToolTip: string;

    unplanned: string;
    unplannedToolTip: string;

    assessing: string;
    assessingToolTip: string;

    shutoff: string;
    shutoffToolTip: string;

    restored: string;
    restoredToolTip: string;

    end: string;
    endToolTip: string;
}

const noPSPS = "No PSPS Outage";
const potentialOutage = "Potential Outage";
const powerOff = "Power is Shut Off";
const powerOffAssessing = "Power is Shut Off (Assessing Outage)";
const patrolling = "Patrolling in Progress";
const powerRestored = "Power Restored";

const NoPSPSToolTip = "Backend state: No PSPS Outage";
const PotentialOutageToolTip = "Backend state: Potential Outage";
const PowerOffToolTip = "Backend state: Power is Shut Off";
const PowerOffAssessingToolTip = "Backend state: Power is Shut Off (Assessing Outage)";
const PatrollingInProgressTooltip = "Backend state: Patrolling in Progress";
const PowerRestoredTooltip = "Backend state: Power is restored";
const noChangeToolTip = "Backend state: Current state remains the same";

const ELEMENT_DATA: RowData[] = [ // row definitions
    { // row 1
        currentState: noPSPS, 
        PotentialOutage: potentialOutage, 
        PotentialOutageToolTip: PotentialOutageToolTip,
        PSPSOutage: powerOff,
        PSPSOutageToolTip: PowerOffToolTip,
        OnPatrol: patrolling,
        OnPatrolToolTip: PatrollingInProgressTooltip,
        PowerRestored: "",
        PowerRestoredToolTip: noChangeToolTip,
        NoPSPS: "",
        NoPSPSToolTip: noChangeToolTip,
        unplanned: "",
        unplannedToolTip: noChangeToolTip,
        assessing: "",
        assessingToolTip: noChangeToolTip,
        shutoff: "",
        shutoffToolTip: noChangeToolTip,
        restored: "",
        restoredToolTip: noChangeToolTip,
        end: "",
        endToolTip: noChangeToolTip
    },
    { // row 2
        currentState: potentialOutage, 
        PotentialOutage: "", 
        PotentialOutageToolTip: noChangeToolTip,
        PSPSOutage: powerOff,
        PSPSOutageToolTip: PowerOffToolTip,
        OnPatrol: patrolling,
        OnPatrolToolTip: PatrollingInProgressTooltip,
        PowerRestored: "",
        PowerRestoredToolTip: noChangeToolTip,
        NoPSPS: noPSPS,
        NoPSPSToolTip: NoPSPSToolTip,
        unplanned: "",
        unplannedToolTip: noChangeToolTip,
        assessing: powerOffAssessing,
        assessingToolTip: PowerOffAssessingToolTip,
        shutoff: powerOff,
        shutoffToolTip: PowerOffToolTip,
        restored: "",
        restoredToolTip: noChangeToolTip,
        end: noPSPS,
        endToolTip: NoPSPSToolTip
    },
    { // row 3
        currentState: "Power is Shut Off (Assessing Outage)", 
        PotentialOutage: "", 
        PotentialOutageToolTip: noChangeToolTip,
        PSPSOutage: powerOff,
        PSPSOutageToolTip: PowerOffToolTip,
        OnPatrol: patrolling,
        OnPatrolToolTip: PatrollingInProgressTooltip,
        PowerRestored: noPSPS,
        PowerRestoredToolTip: NoPSPSToolTip,
        NoPSPS: noPSPS,
        NoPSPSToolTip: NoPSPSToolTip,
        unplanned: "",
        unplannedToolTip: noChangeToolTip,
        assessing: "",
        assessingToolTip: noChangeToolTip,
        shutoff: powerOff,
        shutoffToolTip: PowerOffToolTip,
        restored: powerRestored,
        restoredToolTip: PowerRestoredTooltip,
        end: noPSPS,
        endToolTip: NoPSPSToolTip
    },
    { // row 4
        currentState: "Power is Shut Off (PSPS Outage)", 
        PotentialOutage: "", 
        PotentialOutageToolTip: noChangeToolTip,
        PSPSOutage: "",
        PSPSOutageToolTip: noChangeToolTip,
        OnPatrol: patrolling,
        OnPatrolToolTip: PatrollingInProgressTooltip,
        PowerRestored: powerRestored,
        PowerRestoredToolTip: PowerRestoredTooltip,
        NoPSPS: noPSPS,
        NoPSPSToolTip: NoPSPSToolTip,
        unplanned: "",
        unplannedToolTip: noChangeToolTip,
        assessing: "",
        assessingToolTip: noChangeToolTip,
        shutoff: "",
        shutoffToolTip: noChangeToolTip,
        restored: powerRestored,
        restoredToolTip: PowerRestoredTooltip,
        end: noPSPS,
        endToolTip: NoPSPSToolTip
    },
    { // row 5
        currentState: patrolling, 
        PotentialOutage: "", 
        PotentialOutageToolTip: noChangeToolTip,
        PSPSOutage: "",
        PSPSOutageToolTip: noChangeToolTip,
        OnPatrol: "",
        OnPatrolToolTip: noChangeToolTip,
        PowerRestored: powerRestored,
        PowerRestoredToolTip: PowerRestoredTooltip,
        NoPSPS: noPSPS,
        NoPSPSToolTip: NoPSPSToolTip,
        unplanned: "",
        unplannedToolTip: noChangeToolTip,
        assessing: "",
        assessingToolTip: noChangeToolTip,
        shutoff: "",
        shutoffToolTip: noChangeToolTip,
        restored: powerRestored,
        restoredToolTip: PowerRestoredTooltip,
        end: noPSPS,
        endToolTip: NoPSPSToolTip
    },
    { // row 6
        currentState: powerRestored, 
        PotentialOutage: potentialOutage, 
        PotentialOutageToolTip: PotentialOutageToolTip,
        PSPSOutage: powerOff,
        PSPSOutageToolTip: PowerOffToolTip,
        OnPatrol: "",
        OnPatrolToolTip: noChangeToolTip,
        PowerRestored: "",
        PowerRestoredToolTip: noChangeToolTip,
        NoPSPS: noPSPS,
        NoPSPSToolTip: NoPSPSToolTip,
        unplanned: "",
        unplannedToolTip: noChangeToolTip,
        assessing: powerOffAssessing,
        assessingToolTip: PowerOffAssessingToolTip,
        shutoff: powerOff,
        shutoffToolTip: PowerOffToolTip,
        restored: "",
        restoredToolTip: noChangeToolTip,
        end: noPSPS,
        endToolTip: NoPSPSToolTip
    }
];

@Component({
  selector: 'app-data-override-matrix',
  templateUrl: './data-override-matrix.component.html',
  styleUrls: ['./data-override-matrix.component.css']
})
export class DataOverrideMatrixComponent {
    displayedColumns: string[] = ['currentState', 'PotentialOutage', 'PSPSOutage', 'OnPatrol', 'PowerRestored',
        'NoPSPS', 'unplanned', 'assessing', 'shutoff', 'restored', 'end'];
    dataSource = ELEMENT_DATA;

    @ViewChildren('col1') col1: QueryList<ElementRef>;
    @ViewChildren('col2') col2: QueryList<ElementRef>;
    @ViewChildren('col3') col3: QueryList<ElementRef>;
    @ViewChildren('col4') col4: QueryList<ElementRef>;
    @ViewChildren('col5') col5: QueryList<ElementRef>;
    @ViewChildren('col6') col6: QueryList<ElementRef>;
    @ViewChildren('col7') col7: QueryList<ElementRef>;
    @ViewChildren('col8') col8: QueryList<ElementRef>;
    @ViewChildren('col9') col9: QueryList<ElementRef>;
    @ViewChildren('col10') col10: QueryList<ElementRef>;
    @ViewChildren('col11') col11: QueryList<ElementRef>;
    @ViewChildren('matrow', {read: ElementRef}) matrow: QueryList<ElementRef>;

    constructor() { }

    // on hover, add color to column
    addColumnColor(column: string) {
        // special handling for column 1, ignore hover
        if ( column === "col1") {
            for ( const i of this.matrow ) {
                i.nativeElement.classList.remove("row-hover");
            }
        } else {
            for( const i of this[column] ) {
                i.nativeElement.classList.add("highlight-column-color");
            }
        }
    }

    // on hover over, remove color from column
    removeColumnColor(column: string) {
        // special handling for column 1, ignore hover
        if ( column === "col1") {
            for ( const i of this.matrow ) {
                i.nativeElement.classList.add("row-hover");
            }
        } else {
            for( const i of this[column] ) {
                i.nativeElement.classList.remove("highlight-column-color");
            }
        }
    }
}