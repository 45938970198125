<div id="QA-Blocked-Address-DeleteItemsModalContainer">
    <div mat-dialog-content>
      <div class="right-align">
        <button mat-button [mat-dialog-close]="true" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="header-row" style="padding-bottom: 15px;">
        <span id="QA-Delete-Blocked-Item-Title" class="delete-item-header">Delete Items:</span>
      </div>

      <div class="row">
        <div class="col">
          <mat-list dense role="ids">
            <cdk-virtual-scroll-viewport [itemSize]="ids.length" class="viewport">
              <mat-list-item style="font-size: 13px;" *cdkVirtualFor="let id of ids; let odd = odd;" [class.alternate]="odd" role="id">
                {{id}}
              </mat-list-item>
            </cdk-virtual-scroll-viewport>
          </mat-list>
        </div>
      </div>
    </div>

    <div style="padding-top: 15px;">
        <mat-dialog-actions align="end">
          <button id="QA-Delete-Blocked-Item-Modal-CancelButton" mat-flat-button color="outline" class="delete-items-dialog-actions" [mat-dialog-close]="true">Cancel</button>
          <button id="QA-Delete-Blocked-Item-Modal-SaveButton" mat-flat-button color="primary" class="delete-items-dialog-actions" (click)="deleteItems()">Delete</button>
        </mat-dialog-actions>
      </div>
</div>