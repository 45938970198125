import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MMessageConfig, PostAffectedAddressRequest } from 'src/app/api/affected-address-api-client.service';
import { AffectedAddressService } from 'src/app/core/services/affected-address.service';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MatSelectChange } from '@angular/material/select';
import { SnackBarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { AddressModalInput } from '../../../shared/components/confirmation-modal/confirmation-modal.service';
import { TableUpdateService } from '../table-update/table-update.service';
import { concat, first } from 'rxjs';
import { DefaultLanguage, Languages } from 'src/app/shared/constants/language';

@Component({
  selector: 'app-add-address-modal',
  templateUrl: './add-address-modal.component.html',
  styleUrls: ['./add-address-modal.component.css'],
})
export class AddAddressModalComponent implements OnInit, AfterViewInit {
  @ViewChild('addAddressMapContainer', { static: false }) gMapContainer: ElementRef;
  addAddressForm: FormGroup;
  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private lat: number;
  private lng: number;
  private placeIdForInput: [string, string] = ['', ''];
  isExactDuplicate = false;

  public mMessageLevels: MMessageConfig[] = [];

  public pushNotificationOptions = [
    { name: 'On', value: true },
    { name: 'Off', value: false }
  ];

  private validateRequiredFields = [Validators.required];

  constructor(
    private affectedAddressService: AffectedAddressService,
    private notificationService: NotificationService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddAddressModalComponent>,
    private snackBarService: SnackBarService,
    private tableUpdateService: TableUpdateService,
    public geocodeService: GeocodeService,
    @Inject(MAT_DIALOG_DATA) public data: AddressModalInput) { }

  ngOnInit() {
    this.addAddressForm = this.formBuilder.group({
      address: [null, this.validateRequiredFields],
      sectionalizationDeviceId: [null, this.validateRequiredFields],
      statusSource: [null, this.validateRequiredFields],
      pushNotificationToggle: [null, this.validateRequiredFields],
      estimatedRestorationTime: null
    });

    // filter on English to get unique list of m-message levels
    this.mMessageLevels = this.data.mMessageLevels.filter(level => level.language === Languages.English);
  }

  async ngAfterViewInit() {
    // Default map view will be centered on San Diego
    const geolocation = await this.geocodeService.getGeoLocation('San Diego, California');
    this.lat = geolocation.lat;
    this.lng = geolocation.lng;

    const coords = new google.maps.LatLng(this.lat, this.lng);
    this.map = new google.maps.Map(this.gMapContainer.nativeElement, {
      center: coords,
      zoom: 10,
      disableDefaultUI: true,
      zoomControl: true
    });
  }

  onPlaceSelected(placeDetails: google.maps.places.PlaceResult) {
    const lat = placeDetails.geometry.location.lat();
    const lng = placeDetails.geometry.location.lng();
    this.placeIdForInput[0] = placeDetails.formatted_address;
    this.placeIdForInput[1] = placeDetails.place_id;

    this.addAddressForm.patchValue(
      {
        address: placeDetails.formatted_address
      }
    );
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = this.createMarker(lat, lng);
  }

  async onMessageLevelSelected(messageSelection: MatSelectChange): Promise<void> {
    // Check if ERT field is valid for this message level and disable if not
    this.handleErtDropdowns(messageSelection.value.ertAllowed);

    // Check both the global toggle and the m-message level selected
    concat(
      this.notificationService.isNotificationEnabled(DefaultLanguage, messageSelection.value.id),
      this.notificationService.isNotificationEnabled(DefaultLanguage, 'All')
    ).pipe(first()).subscribe(notificationsEnabled => {
      if (notificationsEnabled) {
        this.addAddressForm.get('pushNotificationToggle').setValue(true);
        this.addAddressForm.get('pushNotificationToggle').enable();
      } else {
        this.disableNotificationDropdown();
      }
    });
  }

  handleErtDropdowns(isEnabled: boolean) {
    if (isEnabled) {
      this.addAddressForm.get('estimatedRestorationTime').enable();
    } else {
      this.addAddressForm.get('estimatedRestorationTime').setValue(null);
      this.addAddressForm.get('estimatedRestorationTime').disable();
    }
  }

  disableNotificationDropdown() {
    this.addAddressForm.get('pushNotificationToggle').setValue(false);
    this.addAddressForm.get('pushNotificationToggle').disable();
  }

  verifyInputFields() {
    // Check that all fields are correctly set before saving address
    this.addAddressForm.markAllAsTouched();
    if (this.addAddressForm.valid) {
      const addressValue = this.addAddressForm.controls.address.value;

      let query = {};
      if (addressValue !== this.placeIdForInput[0]) {
        query = {
          searchCriteria: {
            must: {
              match: {
                addressKey: addressValue.replace(', USA', '').replace(/,/g, '').replace(/ /g, '-').toLowerCase()
              }
            }
          }
        };
      } else {
        query = {
          searchCriteria: {
            must: {
              match: {
                placeId: this.placeIdForInput[1]
              }
            }
          }
        };
      }

      const response = this.affectedAddressService.getActiveAddressesByQuery(query);
      response.subscribe(addressResult => {
        if (addressResult.result.total === 0) {
          this.saveAddressDetails();
        } else {
          const deviceIds = addressResult.result.addresses.map(x => x.sectionalizationDeviceId);
          if (deviceIds.includes(this.addAddressForm.value.sectionalizationDeviceId)) {
            this.isExactDuplicate = true;
          } else {
            this.saveAddressDetails();
          }
        }
      });
    }
  }

  saveAddressDetails() {
    const newAddress = this.createAddAddressRequest();

    this.affectedAddressService.postAffectedAddress(newAddress).subscribe({
      next: response => {
        this.tableUpdateService.addAddressToTable(this.data.addresses, response.result);
        this.snackBarService.showSuccessSnackBar();
        this.dialogRef.close();
      }, error: err => {
        this.snackBarService.showErrorSnackBar(err.message);
        this.dialogRef.close();
      }
    });
  }

  private createAddAddressRequest(): PostAffectedAddressRequest {
    let estimatedRestorationTime: string;

    if (this.addAddressForm.value.estimatedRestorationTime != null) {
      estimatedRestorationTime = (new Date(this.addAddressForm.value.estimatedRestorationTime)).toISOString();
    }

    const addressComponents: string[] = (this.addAddressForm.value.address).split(', ');

    const newAddress: PostAffectedAddressRequest = {
      serviceAddress: {
        address: addressComponents[0],
        city: addressComponents[1],
        state: addressComponents[2].split(' ')[0],
        postalCode: addressComponents[2].split(' ')[1],
        deviceId: this.addAddressForm.value.sectionalizationDeviceId
      },
      mMessage: this.addAddressForm.value.statusSource.id,
      mutePushNotificationToggle: !this.addAddressForm.value.pushNotificationToggle,
      estimatedRestorationTime
    };

    return newAddress;
  }

  setMapMarker(latitude: number, longitude: number) {
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = this.createMarker(latitude, longitude);
  }

  /**
   * Applies the pin to the map at the location's address.
   */
  private createMarker(lat: number, lng: number): google.maps.Marker {
    const coords = new google.maps.LatLng(lat, lng);
    const marker = new google.maps.Marker({
      map: this.map,
      position: coords,
      icon: '/assets/icons/map-pin.svg'
    });
    marker.setMap(this.map);
    if (marker.getPosition()) {
      this.map.setCenter(coords);
    }
    return marker;
  }
}
