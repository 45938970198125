<div id = "QA-EditPushNotificationContent-Modal-EditPushNotificationContentModal" class="add-field">
	<div mat-dialog-content>
		<div class="right-align">
			<button mat-button type="button" [mat-dialog-close]="true" class="close-button">
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div style="padding-top: 10px;">
			<span class="edit-pushnotificationcontent-headers" id="QA-PushNotificationContent-Modal">{{ pushnotificationcontentId }} Settings</span>
		</div>

    <mat-form-field appearance="outline" class="language-select">
      <mat-label>Select language to edit</mat-label>
      <mat-select [(value)]="selectedLanguage" (selectionChange)="changeSelectedLanguage($event)" id="QA-EditPushNotificationContent-Modal-Language-Dropdown">
        <mat-option *ngFor="let lang of languageOptions" [value]="lang.value" id="QA-EditPushNotificationContent-Modal-Language-Dropdown-Option">
          {{ lang.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div [formGroup]="pushNotificationContentForms[selectedLanguage]">
      <div style="height:65px" class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-length">
            <mat-label id="QA-EditPushNotificationContent-Modal-Title">Title</mat-label>
            <input id="QA-EditPushNotificationContent-Modal-TitleInput" matInput formControlName="title" maxlength="40" required
              [placeholder]="pushNotificationContentForms[englishLanguage]?.value.title"
            >
            <mat-error *ngIf="pushNotificationContentForms[selectedLanguage]?.get('title').hasError('required')">This field is required.</mat-error>
          </mat-form-field>
        </div>
        <mat-icon color="primary" class="help" matTooltip="{{ titleTooltip }}" matTooltipPosition="above">help_outline</mat-icon>
      </div>
      <div style="height:65px" class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-length">
            <mat-label id="QA-EditPushNotificationContent-Modal-Body">Body</mat-label>
            <input id="QA-EditPushNotificationContent-Modal-BodyInput" matInput formControlName="body" maxlength="150" required
              [placeholder]="pushNotificationContentForms[englishLanguage]?.value.body"
            >
            <mat-error *ngIf="pushNotificationContentForms[selectedLanguage]?.get('body').hasError('required')">This field is required.</mat-error>
          </mat-form-field>
        </div>
        <mat-icon color="primary" class="help" matTooltip="{{ bodyTooltip }}" matTooltipPosition="above">help_outline</mat-icon>
      </div>
      <div style="height:65px" class="row">
        <mat-form-field floatLabel="always" appearance="none"
          matTooltip="{{ selectedLanguage !== englishLanguage ? onlyEnglishEditableTooltip : null }}" matTooltipPosition="above"
        >
          <mat-slide-toggle formControlName="notificationsEnabled" id="QA-EditPushNotificationContent-Modal-Notification-Toggle">Notifications Enabled</mat-slide-toggle>
          <textarea matInput hidden></textarea>
        </mat-form-field>
      </div>
      <div style="padding-bottom: 10px;">
        <mat-dialog-actions align="end">
          <button id="QA-EditPushNotificationContent-Modal-CancelButton" mat-flat-button color="outline" class="edit-pushnotificationcontent-dialog-actions" (click)="closeDialog()">Cancel</button>
          <button [disabled]="determineDisableSaveButton()" id="QA-EditPushNotificationContent-Modal-SaveButton" mat-flat-button color="primary" class="edit-pushnotificationcontent-dialog-actions" (click)="verifyRequiredFields()">Save</button>
        </mat-dialog-actions>
      </div>
    </div>
	</div>
</div>
