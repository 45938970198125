import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarType } from './snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  showSuccessSnackBar(message?: string) {

    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 7000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'snackbar-success',
      data: {
        type: SnackBarType.success,
        header: 'Success!',
        message: message ?? 'Updates saved successfully'
      }
    });
  }

  showErrorSnackBar(message?: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 7000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
      data: {
        type: SnackBarType.error,
        header: 'Something went wrong',
        message: message ?? 'There was an error saving changes'
      }
    });
  }

  showInfoSnackBar(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 7000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'snackbar-info',
      data: {
        type: SnackBarType.info,
        header: 'Information',
        message
      }
    });
  }
}
