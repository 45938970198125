<div id="QA-BlockedAddressPageContainer" class="container">
  <ng-container>
    <div class="manage-header">
      <h6 id="QA-BlockeddAddress-Title">
        Blocked Zip Codes and Addresses
      </h6>
      <ng-container matColumnDef="AddPostalCode">
        <span>
          <button id="QA-AffectedAddress-AddBlockedPostalCodeButton" mat-button color="primary" class="add-button"
          (click)="openAddPostalCodeModal()">+ Add Postal Code</button>
          &nbsp;
          <button id="QA-AffectedAddress-AddBlockedAddressButton" mat-button color="primary" class="add-button"
          (click)="openAddAddressModal()">+ Add Address</button>
          &nbsp;
          <button *ngIf="selection.selected.length > 0" id="QA-AffectedAddress-DeleteBlockedItemButton" mat-raised-button color="warn" class="delete-button"
          (click)="openDeletePostalCodeModal()">- Delete Item(s)</button>
        </span>
      </ng-container>
    </div>

    <div fxLayout="row">
      <form [formGroup]="filters" fxLayoutGap="9px">
        <div class="filter-container" fxLayout="row">
          <mat-form-field appearance="outline" id="QA-Blocked-AddressSearch" fxFlex>
            <mat-label>Filter by address</mat-label>
            <button mat-button matSuffix (click)="onSearchCriteriaChanged('address', $event)" mat-icon-button>
              <mat-icon id="QA-AddressSearch-SearchIcon" matSuffix>search</mat-icon>
            </button>
            <input id="QA-Blocked-AddressSearchInput" matInput formControlName="addressSearch" #input/>
          </mat-form-field>
          <mat-form-field appearance="outline" id="QA-Blocked-ZipSearch" fxFlex>
            <mat-label>Filter by postal code</mat-label>
            <button mat-button matSuffix (click)="onSearchCriteriaChanged('postalCode', $event)" mat-icon-button>
              <mat-icon id="QA-ZipSearch-SearchIcon" matSuffix>search</mat-icon>
            </button>
            <input id="QA-Blocked-ZipSearchInput" matInput formControlName="zipSearch" #input
                (keyup.enter)="onSearchCriteriaChanged('postalCode', $event)"/>
          </mat-form-field>
        </div>
      </form>
    </div>

    <table mat-table id="blockedAddressesList" [dataSource]="blockedAddressesList">
      <ng-container matColumnDef="Checkbox">
        <th id="QA-BlockedAddress-Row-CheckboxHeader" mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td id="QA-BlockedAddress-Row-Checkbox" mat-cell *matCellDef="let element">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="Address">
        <th id="QA-BlockedAddress-Row-AddressHeader" mat-header-cell *matHeaderCellDef> Address </th>
        <td id="QA-BlockedAddress-Row-Address" mat-cell *matCellDef="let element"> {{ element.addressKey || '-' }} </td>
      </ng-container>

      <ng-container matColumnDef="Zip">
        <th id="QA-BlockedAddress-Row-ZipHeader" mat-header-cell *matHeaderCellDef> Zip </th>
        <td id="QA-BlockedAddress-Row-Zip" mat-cell *matCellDef="let element"> {{ element.postalCode || '-' }} </td>
      </ng-container>

      <ng-container matColumnDef="BlockedPermanently">
        <th id="QA-BlockedAddress-Row-PermanentHeader" mat-header-cell *matHeaderCellDef> Blocked Permanently </th>
        <td id="QA-BlockedAddress-Row-Permanent" mat-cell *matCellDef="let element"> {{ element.eventId ? false : true }} </td>
      </ng-container>

      <ng-container matColumnDef="CreatedOn">
        <th id="QA-BlockedAddress-Row-CreatedOnHeader" mat-header-cell *matHeaderCellDef> Created On </th>
        <td id="QA-BlockedAddress-Row-CreatedOn" mat-cell *matCellDef="let element"> {{ element.createdOn | date: "M/d/yyyy \'at\' h:mm a" }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="50"
      [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>
  </ng-container>

  <ng-template #blockLoading><br><br>Loading Blocked Zip Codes and Addresses data...</ng-template>
</div>