import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export enum SnackBarType {
  info = 'info',
  error = 'remove_circle',
  success = 'check_circle'
}

export interface SnackBarInput {
  type: SnackBarType;
  header: string;
  message: string;
}

@Component({
  selector: 'app-snackbar',
  templateUrl: 'snackbar.html',
  styleUrls: ['./snackbar.css']
})
export class SnackBarComponent {
  snackBarIcon: string;
  snackBarHeader: string;
  snackBarMessage: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) input: SnackBarInput) {
    this.snackBarMessage = input.message;
    this.snackBarIcon = input.type;
    this.snackBarHeader = input.header;
  }
}
