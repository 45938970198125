import { Pipe, PipeTransform } from '@angular/core';
/*
 * Takes the numerical status and returns the string name value.
 * Usage:
 *   value | statusName
 * Example:
 *   {{ 2 | statusName }}
 *   formats to: 'PSPSOutage'
 *
 *   {{ "3" | statusName }}
 *   formats to: 'UnplannedOutage'
*/
@Pipe({name: 'statusName'})
export class StatusNamePipe implements PipeTransform {
  transform(value: string | number): string {
    // If input is undefined, return empty string
    if (!value) {
      return '';
    } else if (value === typeof Number) {
      return this.evaluateNumericStatus(+value);
    } else {
      if (isNaN(parseInt(value.toString(), 10))) {
        return value.toString();
      } else {
        return this.evaluateNumericStatus(parseInt(value.toString(), 10));
      }
    }
  }

  private evaluateNumericStatus(value: number): string {
    switch (value) {
      case 0:
        return 'NoEvent';
      case 1:
        return 'PotentialOutage';
      case 2:
        return 'PSPSOutage';
      case 3:
        return 'UnplannedOutage';
      case 4:
        return 'OnPatrol';
      case 5:
        return 'PowerRestored';
      case 6:
        return 'AssessingOutage';
      default:
        // If no matches found, return the original value
        return value.toString();
    }
  }
}
