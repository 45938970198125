import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AffectedAddressService } from '../../core/services/affected-address.service';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';

@Component({
  selector: 'app-delete-blocked-item-modal',
  templateUrl: './delete-blocked-item-modal.component.html',
  styleUrls: ['./delete-blocked-item-modal.component.css']
})
export class DeleteBlockedItemModalComponent implements OnInit {
  // blocked addresses in dynamodb table
  public blockedAddresses$ = this.affectedAddressService.addresses$;
  public ids: string[] = [];

  constructor(
    private snackBarService: SnackBarService,
    private affectedAddressService: AffectedAddressService,
    public dialogRef: MatDialogRef<DeleteBlockedItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
      this.ids = this.data.ids;
    }

    closeDialog(): void {
      this.dialogRef.close();
    }

    async deleteItems() {
      this.affectedAddressService.deleteBlockedItem(this.ids).subscribe({
        next: () => {
          this.snackBarService.showSuccessSnackBar("Delete Successful");
          this.data.unselect();
          this.dialogRef.close();
        }, error: () => {
          this.snackBarService.showErrorSnackBar("Error on delete attempt, please try again later");
          this.data.unselect();
          this.dialogRef.close();
        }
      });
    }
}