import { Pipe, PipeTransform } from '@angular/core';
import { ActiveAddress } from 'src/app/api/affected-address-api-client.service';

@Pipe({name: 'fullAddress'})
export class FullAddressPipe implements PipeTransform {
  transform(address: ActiveAddress): string {
    if (address.address && address.city && address.zip) {
      return address.address + ', ' + address.city + ', CA ' + address.zip;
    } else {
      return 'Unknown';
    }
  }
}
