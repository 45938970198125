<div id="QA-Blocked-Address-AddPostalCodeModalContainer" [formGroup]="addPostalCodeForm" class="add-field">
    <div mat-dialog-content>
      <div class="right-align">
        <button mat-button [mat-dialog-close]="true" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="header-row">
        <span id="QA-Add-Postal-Code-Title" class="postal-code-header">Add New Postal Code</span>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-length">
            <mat-label>Postal Code</mat-label>
            <input matInput formControlName="postalCode" id="QA-addPostalCode-Modal-PostalCodeInput" required minlength="5" maxlength="5">
            <mat-error *ngIf="addPostalCodeForm.get('postalCode').hasError('required')">This field is required.</mat-error>
            <mat-error *ngIf="addPostalCodeForm.get('postalCode').hasError('minlength')">This field must be 5 digits.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
            <mat-form-field appearance="outline" class="full-length">
                <mat-label>Blocked Permanently</mat-label>
                <mat-select id="QA-addPostalCode-Modal-BlockedPermanentlyDropdown" formControlName="blockedPermanently" required>
                    <mat-option *ngFor="let option of blockPermanentlyOptions" [value]="option.value" id="QA-BlockPermanently-DropDown-Option">
                      {{ option.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="addPostalCodeForm.get('blockedPermanently').hasError('required')">This field is required.</mat-error>
            </mat-form-field>
        </div>
        <mat-icon color="primary" class="help" matTooltip="{{ blockedPermanentlyTooltip }}" matTooltipPosition="above"
          matTooltipClass="add-postal-code-tooltip">help_outline</mat-icon>
      </div>
      <mat-error *ngIf="!activeEvent && addPostalCodeForm.get('blockedPermanently').value===false">There is no active PSPS event, the 'Block Permanently?' toggle must be enabled.</mat-error>
    </div>

    <div style="padding-top: 15px;">
        <mat-dialog-actions align="end">
          <button id="QA-Add-Postal-Code-Modal-CancelButton" mat-flat-button color="outline" class="postal-code-dialog-actions" [mat-dialog-close]="true">Cancel</button>
          <button id="QA-Add-Postal-Code-Modal-SaveButton" mat-flat-button color="primary" class="postal-code-dialog-actions" (click)="validateNewPostalCode()">Add</button>
        </mat-dialog-actions>
      </div>
</div>