import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, skipWhile, switchMap } from 'rxjs/operators';
import { AffectedAddressApiClientService, Bounds } from 'src/app/api/affected-address-api-client.service';

export interface SearchState {
  searchTerm: string;
  searchResults: google.maps.places.AutocompletePrediction[];
  searchBounds: Bounds;
  loading: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class SearchViewModelService {
  private state: SearchState = {
    searchTerm: '',
    searchResults: [],
    searchBounds: null,
    loading: false
  };
  private store = new BehaviorSubject<SearchState>(this.state);
  private state$ = this.store.asObservable();

  searchTerm$ = this.state$.pipe(
    map(state => state.searchTerm),
    distinctUntilChanged()
  );

  searchResults$ = this.state$.pipe(
    map(state => state.searchResults),
    distinctUntilChanged()
  );

  searchBounds$ = this.state$.pipe(
    map(state => state.searchBounds),
    distinctUntilChanged()
  );

  loading$ = this.state$.pipe(
    map(state => state.loading),
    distinctUntilChanged()
  );

  /**
   * Viewmodel that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<SearchState> = combineLatest([
    this.searchTerm$,
    this.searchResults$,
    this.searchBounds$,
    this.loading$
  ]).pipe(
    map(([searchTerm, searchResults, searchBounds, loading]) => {
      return { searchTerm, searchResults, searchBounds, loading };
    })
  );

  constructor(private affectedAddressApi: AffectedAddressApiClientService) {
    this.affectedAddressApi
      .getServiceArea()
      .pipe(map(response => response.result))
      .subscribe(res =>
        this.updateState({
          ...this.state,
          searchBounds: res.bounds,
        })
      );

    this.searchTerm$
      .pipe(
        switchMap(searchTerm => this.getSearchResults(searchTerm))
      )
      .subscribe(searchResults => {
        this.updateState({
          ...this.state,
          searchResults,
          loading: false
        });
      }
      );
  }

  /**
   * Updates the serchTerm and refreshes searchResults$.
   *
   * @param searchTerm partial address value to query for
   */
  updateSearchTerm(searchTerm: string): void {
    this.updateState({ ...this.state, searchTerm, loading: true });
  }

  clear(): void {
    this.updateState({ ...this.state, searchTerm: '', searchResults: [], loading: false });
  }

  removeSearchResult(placeId: string): void {
    this.updateState({
      ...this.state,
      searchResults: this.state.searchResults.filter(i => i.place_id !== placeId)
    });
  }

  /**
   * Returns an observable of a Google PlaceResult object after making an Google Maps API request.
   *
   * @param placeId Google Maps placeId
   */
  getPlaceDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
    // The PlacesService requires a HTML container element to be instantiated. Since no template is available
    // to the view model service, a dynamically created div is provided in its place.
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div')
    );
    const placeRequest = {
      placeId,
      fields: ['geometry', 'address_components', 'place_id', 'formatted_address']
    };

    return new Observable(o => {
      placesService.getDetails(placeRequest, result =>
        result ? o.next(result) : o.complete()
      );
    });
  }

  /** Update internal state cache and emit from store... */
  private updateState(state: SearchState) {
    this.store.next((this.state = state));
  }

  private getSearchResults(searchTerm: string): Observable<google.maps.places.AutocompletePrediction[]> {
    const autocompleteService = new google.maps.places.AutocompleteService();

    if (!searchTerm) {
      return of([]);
    }

    return this.searchBounds$.pipe(
      skipWhile(bounds => bounds === null),
      switchMap(
        bounds =>
          new Observable<google.maps.places.AutocompletePrediction[]>(o => {
            autocompleteService.getPlacePredictions(
              {
                input: searchTerm,
                types: ['address'],
                componentRestrictions: {
                  country: 'us'
                },
                bounds
              },
              result => o.next(result || [])
            );
          })
      )
    );
  }
}
