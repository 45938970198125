import { Component } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent {

  public isLoggedIn$ = this.authService.isSignedIn$;

  dataMenuItems = [
    {
      path: '/bad-addresses',
      label: 'Invalid Address Management'
    },
    {
      path: '/blocked-address',
      label: 'Blocked Addresses'
    }
  ];

  eventMenuItems = [
    {
      path: '/home',
      label: 'Home'
    },
    {
      path: '/community-centers',
      label: 'CRC Management'
    },
    {
      path: '/dashboard',
      label: 'Monitoring Dashboard'
    },
    {
      path: '/affected-addresses',
      label: 'Affected Addresses'
    }
  ];

  rulesMenuItems = [
    {
      path: '/mmessage',
      label: 'M-Message Settings'
    },
    {
      path: '/push-notification-content',
      label: 'Push Notification Settings'
    },
    {
      path: '/map-configuration',
      label: 'Map Display Settings'
    },
    {
      path: '/data-override-matrix',
      label: 'Data Override'
    }
  ];

  constructor(public authService: AuthService) { }

  logOut() {
    this.authService.signOut();
  }
}
