<div id = "QA-EditMMessage-Modal-EditMMessageModal" [formGroup]="mMessageForms[selectedLanguage]" class="add-field">
	<div mat-dialog-content>
		<div class="right-align">
			<button mat-button type="button" [mat-dialog-close]="true" class="close-button">
				<mat-icon>close</mat-icon>
			</button>
		</div>
    <mat-form-field *ngIf="!data.isNewCenter" appearance="outline" class="language-select">
      <mat-label>Select language to edit</mat-label>
      <mat-select [(value)]="selectedLanguage" (selectionChange)="changeSelectedLanguage($event)" id="QA-Mmessage-Modal-Language-Dropdown">
        <mat-option *ngFor="let lang of languageOptions" [value]="lang.value" id="QA-Mmessage-Modal-Language-Dropdown-Option">
          {{ lang.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
		<div style="padding-top: 20px;">
			<span class="edit-mmessage-headers" id="QA-MMessage-Modal">{{ mmessageId }} Settings</span>
		</div>
		<div style="height:65px" class="row">
			<mat-form-field floatLabel="always" appearance="none"
        matTooltip="{{ selectedLanguage !== englishLanguage ? onlyEnglishEditableTooltip : null }}" matTooltipPosition="above"
      >
        <mat-slide-toggle formControlName="ingestionEnabled" id="QA-Edit-MMessage-Modal-Ingestion-Toggle">Ingestion Enabled</mat-slide-toggle>
        <textarea matInput hidden></textarea>
			</mat-form-field>
		</div>
		<div style="height:65px" class="row">
			<mat-form-field floatLabel="always" appearance="none"
        matTooltip="{{ selectedLanguage !== englishLanguage ? onlyEnglishEditableTooltip : null }}" matTooltipPosition="above"
      >
        <mat-slide-toggle formControlName="ertAllowed" id="QA-Edit-MMessage-Modal-ERT-Toggle">ERT Allowed</mat-slide-toggle>
        <textarea matInput hidden></textarea>
			</mat-form-field>
		</div>
		<div style="height:65px" class="row">
			<mat-form-field floatLabel="always" appearance="none"
        matTooltip="{{ selectedLanguage !== englishLanguage ? onlyEnglishEditableTooltip : null }}" matTooltipPosition="above"
      >
        <mat-slide-toggle formControlName="canStartPsps" id="QA-Edit-MMessage-Modal-StartPSPS-Toggle">Can Start PSPS</mat-slide-toggle>
        <textarea matInput hidden></textarea>
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field appearance="outline" class="half-length"
        matTooltip="{{ selectedLanguage !== englishLanguage ? onlyEnglishEditableTooltip : null }}" matTooltipPosition="above"
      >
				<mat-label>Status</mat-label>
				<mat-select id="QA-MMessageConfig-Modal-Status-Dropdown" formControlName="status" required>
          <mat-option *ngFor="let stat of mMessageStatusList" [value]="stat" id="QA-MMessageConfig-Modal-Status-Dropdown-Option">{{ stat }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="row">
			<mat-form-field appearance="outline" class="full-length">
				<mat-label id="QA-EditMMessage-Modal-TrackerText">Tracker Text</mat-label>
				<input id="QA-EditMMessage-Modal-TrackerTextInput" matInput formControlName="trackerText" maxlength="180"
          [placeholder]="mMessageForms[englishLanguage]?.value.trackerText"
        >
        <mat-error *ngIf="mMessageForms[selectedLanguage].get('trackerText').hasError('maxlength')">This field must be less than 180 characters</mat-error>
			</mat-form-field>
		</div>
		<div style="padding-bottom: 10px;">
			<mat-dialog-actions align="end">
				<button id="QA-EditMMessage-Modal-CancelButton" mat-flat-button color="outline" class="edit-mmessage-dialog-actions" (click)="closeDialog()">Cancel</button>
				<button id="QA-EditMMessage-Modal-SaveButton" [disabled]="determineDisableSaveButton()" mat-flat-button color="primary" class="edit-mmessage-dialog-actions" (click)="verifyRequiredFields()">Save</button>
			</mat-dialog-actions>
		</div>
	</div>
</div>
