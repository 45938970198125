<div [formGroup]="addressDetailsForm" class="add-field">
  <div mat-dialog-content>
    <div class="right-align">
      <button mat-button [mat-dialog-close]="true" class="close-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="header-row">
      <span class="address-details-header">Affected Address Details:</span>
    </div>
    <div #addressDetailsMapContainer class="map">
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-length">
        <mat-label>Street Address</mat-label>
        <input matInput formControlName="fullAddress">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-length">
        <mat-label>Sectionalizing Device</mat-label>
        <input matInput formControlName="sectionalizationDeviceId">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-length">
        <mat-label>Current M-Message</mat-label>
        <mat-select formControlName="statusSource" (selectionChange)="onMessageLevelSelected($event)">
          <mat-option *ngFor="let level of messageLevels" [value]="level">
            {{ level.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-length">
          <mat-label>Estimated Restoration Time (ERT)</mat-label>
          <input matInput type="datetime-local" formControlName="estimatedRestorationTime">
        </mat-form-field>
      </div>
    </div>
    <div></div>
    <div class="status-history">
      <span class="address-details-header">Status History</span>
    </div>
    <div>
      <div class="address-history-card">
        <table mat-table [dataSource]="addressHistory" class="address-history-table" matSort>
          <ng-container matColumnDef="Summary">
            <th mat-header-cell *matHeaderCellDef> Summary </th>
            <td mat-cell *matCellDef="let element"> {{ element.audit || 'Changed status to ' + element.statusSource }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Editor">
            <th mat-header-cell *matHeaderCellDef> Editor </th>
            <td mat-cell *matCellDef="let element"> {{ element.createdBy }} </td>
          </ng-container>

          <ng-container matColumnDef="Time of Change">
            <th mat-header-cell *matHeaderCellDef> Time of Change </th>
            <td mat-cell *matCellDef="let element"> {{ element.createdOn * 1000 | date:'short' }} </td>
          </ng-container>

          <ng-container matColumnDef="Source">
            <th mat-header-cell *matHeaderCellDef> Source </th>
            <td mat-cell *matCellDef="let element"> {{ element.createdBy !== 'ENS' ? 'Portal' : element.statusSource |
              fileSource }} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedHistoryColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedHistoryColumns;"></tr>
        </table>
      </div>
    </div>
    <div style="padding-top: 15px;">
      <mat-dialog-actions align="end">
        <button mat-flat-button color="outline" class="address-details-dialog-actions"
          [mat-dialog-close]="true">Cancel</button>
        <button mat-flat-button color="primary" class="address-details-dialog-actions"
          (click)="saveAddressDetails()">Save</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>