import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  ActiveAddressesResponse,
  AddressHistoryResponse,
  AffectedAddressApiClientService,
  AffectedAreaResponseV2,
  BlockedAddressItem,
  BulkOperationResponse,
  GetBlockedAddressResponse,
  MMessageConfigResponse,
  NonmatchedAddressGetResponse,
  PatchAffectedAddressItem,
  PostAffectedAddressRequest,
  PostAffectedAddressResponse,
  PSPSEventStatus,
  PSPSEventStatusResponse,
  PSPSEventStatusStatus,
  PutNonMatchedAddressRequest,
  PutNonMatchedAddressResponse,
  MMessageConfig,
  PutMMessageConfigRequest,
  PostMMessageConfigRequest,
  AdHocNotificationPostRequest,
  AdHocNotificationPostResponse
} from '../../api/affected-address-api-client.service';

export interface PspsEventState {
  eventStatus: PSPSEventStatus;
  isActiveEvent: boolean;
  loading: boolean;
}

export interface BlockedAddressState {
  addresses: BlockedAddressItem[];
  loading: boolean;
}

export interface MetricDataRequestParams {
  namespace: string;
  metricName: string;
  dimensionName?: string;
  dimensionValue?: string;
  startTime: string;
  endTime: string;
  period: string;
  stat: string;
  unit: string;
}

export interface MetricImageRequestParams {
  imageName: string;
  height: string;
  width: string;
  start: string;
}

@Injectable({
  providedIn: 'root'
})

export class AffectedAddressService {
  private state: PspsEventState = {
    eventStatus: null,
    isActiveEvent: null,
    loading: false
  };

  private blockedState: BlockedAddressState = {
    addresses: null,
    loading: false
  }

  private store = new BehaviorSubject<PspsEventState>(this.state);
  private state$ = this.store.asObservable();

  isActiveEvent$ = this.state$.pipe(
    map(state => state.isActiveEvent),
    distinctUntilChanged()
  );

  loading$ = this.state$.pipe(map(state => state.loading));

  private blockStore = new BehaviorSubject<BlockedAddressState>(this.blockedState);
  private blockState$ = this.blockStore.asObservable();

  addresses$ = this.blockState$.pipe(
    map(blockedState => blockedState.addresses),
    distinctUntilChanged()
  );

  blockLoading$ = this.blockState$.pipe(map(blockedState => blockedState.loading));

  constructor(private affectedAddressApi: AffectedAddressApiClientService) {
    this.refreshPspsEventStatus();
    this.refreshBlockedAddresses();
  }

  refreshPspsEventStatus() {
    this.getPspsEventStatus().pipe(map(response => response.result))
      .subscribe((eventStatus) => {
        this.updateState({
          ...this.state,
          eventStatus,
          isActiveEvent: (eventStatus.status === PSPSEventStatusStatus.PSPSEventInProgress),
          loading: false
        });
      });
  }

  getPspsEventStatus(): Observable<PSPSEventStatusResponse> {
    return this.affectedAddressApi.getPSPSEventStatus();
  }

  private updateState(state: PspsEventState) {
    this.store.next((this.state = state));
  }

  updatePspsEventStatus(status: boolean): Observable<void> {
    this.updateState({ ...this.state, loading: true });

    return this.affectedAddressApi.putPSPSEventStatus(status.toString()).pipe(
      tap(() => {
        this.refreshPspsEventStatus();
      })
    );
  }

  getAddressHistory(id: string): Observable<AddressHistoryResponse> {
    return this.affectedAddressApi.getAddressHistory(id);
  }

  postAffectedAddress(request: PostAffectedAddressRequest): Observable<PostAffectedAddressResponse> {
    return this.affectedAddressApi.postAffectedAddress(request);
  }

  patchAffectedAddresses(
    existingAddresses: PatchAffectedAddressItem[],
    mutePushNotificationToggle?: boolean
  ): Observable<BulkOperationResponse> {
    return this.affectedAddressApi.patchAffectedAddress({ updates: existingAddresses, mutePushNotificationToggle });
  }

  private updateBlockedState(state: BlockedAddressState) {
    this.blockStore.next((this.blockedState = state));
  }

  refreshBlockedAddresses() {
    this.getBlockedAddresses().pipe(map(response => response.result))
      .subscribe((blockedAddresses) => {
        this.updateBlockedState({
          ...this.blockedState,
          addresses: blockedAddresses,
          loading: false
        });
      });
  }

  getBlockedAddresses(): Observable<GetBlockedAddressResponse> {
    return this.affectedAddressApi.getBlockedAddresses();
  }

  blockAddresses(addressKeys: string[], blockPermanently: boolean = false): Observable<BulkOperationResponse> {
    return this.affectedAddressApi.blockAddress({ addressKeys, blockPermanently });
  }

  deleteBlockedItem(ids: string[]): Observable<BulkOperationResponse> {
    return this.affectedAddressApi.deleteBlockedAddress({ ids }).pipe(
      tap(() => {
        this.refreshBlockedAddresses();
      })
    );
  }

  blockPostalCodes(postalCodes: string[], blockPermanently: boolean): Observable<BulkOperationResponse> {
    this.updateBlockedState({ ...this.blockedState, loading: true });
    return this.affectedAddressApi.blockAddress({ postalCodes: postalCodes, blockPermanently: blockPermanently }).pipe(
      tap(() => {
        this.refreshBlockedAddresses();
      })
    );
  }

  addBlockedAddress(addressKeys: string[], blockPermanently: boolean): Observable<BulkOperationResponse> {
    this.updateBlockedState({ ...this.blockedState, loading: true });
    return this.blockAddresses(addressKeys, blockPermanently).pipe(
      tap(() => {
        this.refreshBlockedAddresses();
      })
    );
  }

  getActiveAddressesByQuery(query: any): Observable<ActiveAddressesResponse> {
    return this.affectedAddressApi.getActiveAddresses(query);
  }

  getAffectedAreaV2(): Observable<AffectedAreaResponseV2> {
    return this.affectedAddressApi.getAffectedAreaV2();
  }

  refreshMap(): Observable<void> {
    return this.affectedAddressApi.putAffectedArea();
  }

  getMMessageConfig(): Observable<MMessageConfigResponse> {
    return this.affectedAddressApi.getMMessageConfig();
  }

  updateMMessageConfig(language: string, id: string, request: PutMMessageConfigRequest): Observable<MMessageConfig> {
    return this.affectedAddressApi.putMMessageConfig(language, id, request);
  }

  addMMessageConfig(request: PostMMessageConfigRequest): Observable<MMessageConfig> {
    return this.affectedAddressApi.postMMessageConfig(request);
  }

  deleteMMessageConfig(language: string, id: string): Observable<void> {
    return this.affectedAddressApi.deleteMMessageConfig(language, id);
  }

  getNonmatchedAddresses(
    address: string,
    searchAfter: [number, string],
    city: string[],
    sortOrder: string,
    postalCode: string[],
    size: string,
    isMapped?: string
  ): Observable<NonmatchedAddressGetResponse> {
    const searchAfterTime = searchAfter ? searchAfter[0] : null;
    const searchAfterId = searchAfter ? searchAfter[1] : null;
    return this.affectedAddressApi.getNonmatchedAddresses(sortOrder, searchAfterTime?.toString(), address, city, isMapped, searchAfterId, postalCode, size);
  }

  mapAddress(request: PutNonMatchedAddressRequest): Observable<PutNonMatchedAddressResponse> {
    return this.affectedAddressApi.mapAddress(request);
  }

  sendAdHocNotification(request: AdHocNotificationPostRequest): Observable<AdHocNotificationPostResponse> {
    return this.affectedAddressApi.adHocNotificationPost(request);
  }
}
