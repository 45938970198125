import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  AddPushNotificationRequest,
  NotificationApiClientService,
  PushNotificationContentResponse,
  UpdatePushNotificationContentResponse,
  DeletePushNotificationContentResponse,
  UpdatePushNotificationRequest,
  AddPushNotificationContentResponse
} from '../../api/notification-api-client.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private notificationApi: NotificationApiClientService) {
  }

  getPushNotificationContent(language: string, id: string): Observable<UpdatePushNotificationContentResponse> {
    return this.notificationApi.getPushNotificationContent(language,id);
  }

  getAllPushNotificationContent(): Observable<PushNotificationContentResponse> {
    return this.notificationApi.getAllPushNotificationContent();
  }

  updatePushNotificationContent(language: string, id: string, request: UpdatePushNotificationRequest): Observable<UpdatePushNotificationContentResponse> {
    return this.notificationApi.updatePushNotificationContent(language, id, request);
  }

  addPushNotificationContent(request: AddPushNotificationRequest): Observable<AddPushNotificationContentResponse> {
    return this.notificationApi.createPushNotificationContent(request);
  }

  deletePushNotificationContent(language: string, id: string): Observable<DeletePushNotificationContentResponse> {
    return this.notificationApi.deletePushNotificationContent(language, id);
  }

  isNotificationEnabled(language: string, id: string): Observable<boolean> {
    return this.getPushNotificationContent(language, id).pipe(map(response => {
      return response.result.sendPushNotification ?? false;
    }));
  }
}
