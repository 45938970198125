<div id="QA-Affected-Address-AddAddressModalContainer" [formGroup]="addAddressForm" class="add-field">
  <div mat-dialog-content>
    <div class="right-align">
      <button mat-button [mat-dialog-close]="true" class="close-button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="header-row">
      <span id="QA-Add-Address-Title" class="address-header">Add New Address</span>
    </div>
    <div
      id="QA-addAddress-Modal-GoogleMap"
      #addAddressMapContainer
      class="map"
    >
    </div>
    <div>
      <app-search-google-autocomplete
        appearance="outline"
        label="Street Address"
        floatLabel="auto"
        (placeSelected)="onPlaceSelected($event)"
        class="full-length"
        required="true"
        [externalInvalid]="isExactDuplicate"
        externalValidationError="This address/device pair is already part of the current event and can not be added to the event"
      ></app-search-google-autocomplete>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-length">
        <mat-label>Sectionalizing Device</mat-label>
        <input matInput formControlName="sectionalizationDeviceId" required id="QA-addAddress-Modal-DeviceIdInput">
        <mat-error *ngIf="addAddressForm.get('sectionalizationDeviceId').hasError('required')">This field is required.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-length">
        <mat-label>Current M-Message</mat-label>
          <mat-select formControlName="statusSource"
            disableOptionCentering
            panelClass="psps-add-address-panel-class"
            required
            (selectionChange)="onMessageLevelSelected($event)"
            id="statusSource"
          >
            <mat-option *ngFor="let level of mMessageLevels" [value]="level" [id]="level.id">
              {{ level.id }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addAddressForm.get('statusSource').hasError('required')">This field is required.</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-length">
        <mat-label>Push Notifications</mat-label>
        <mat-select formControlName="pushNotificationToggle"
          disableOptionCentering
          panelClass="psps-add-address-panel-class"
          required
        >
          <mat-option *ngFor="let option of pushNotificationOptions" [value]=option.value>
            {{ option.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addAddressForm.get('pushNotificationToggle').hasError('required')">This field is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-length">
          <mat-label>Estimated Restoration Time (ERT)</mat-label>
          <input matInput type="datetime-local" formControlName="estimatedRestorationTime" id="QA-addAddress-Modal-ErtInput">
        </mat-form-field>
      </div>
    </div>
    <div style="padding-top: 15px;">
      <mat-dialog-actions align="end">
        <button id="QA-Add-Address-Modal-CancelButton" mat-flat-button color="outline" class="address-dialog-actions" [mat-dialog-close]="true">Cancel</button>
        <button id="QA-Add-Address-Modal-SaveButton" mat-flat-button color="primary" class="address-dialog-actions" (click)="verifyInputFields()">Add</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>