import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PutMMessageConfigRequest, AffectedAddressStatus, PostMMessageConfigRequest, MMessageConfig } from '../../api/affected-address-api-client.service';
import { AffectedAddressService } from '../../core/services/affected-address.service';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';
import { Languages } from 'src/app/shared/constants/language';
import { languageDisplayOptions } from 'src/app/shared/utils/multilanguage/multilanguage-helpers.util';
import { MatSelectChange } from '@angular/material/select';
import { combineLatest, Observable } from 'rxjs';


@Component({
	selector: 'app-edit-mmessage',
	templateUrl: './edit-mmessage-modal.component.html',
	styleUrls: ['./edit-mmessage-modal.component.css']
})

export class EditMMessageComponent implements OnInit {
  mMessageForms: { [language: string]: FormGroup } = {};
  mmessageId: string;
  mMessageStatusList: string[] =  Object.values(AffectedAddressStatus);

  mMessageUpdate: PutMMessageConfigRequest;

  status: string;

  englishLanguage = Languages.English;
  selectedLanguage = this.englishLanguage; // this also sets the only language they can add if creating a new M-Message

  languageOptions = languageDisplayOptions;
  languageEntryExists = {};
  onlyEnglishEditableTooltip = 'This field applies across all languages and can only be edited when English is selected.'

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private affectedAddressService: AffectedAddressService,
    public dialogRef: MatDialogRef<EditMMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    for (let langOptions of this.languageOptions) {
      const lang = langOptions.value;
      const mMessageConfigByLanguageEntry = this.data.mMessageConfigByLanguage?.[lang];
      const mMessageConfigByLanguageEnglishEntry = this.data.mMessageConfigByLanguage?.[this.englishLanguage];

      this.languageEntryExists[lang] = mMessageConfigByLanguageEntry != null;

      this.mMessageForms[lang] = this.formBuilder.group({
        id: mMessageConfigByLanguageEntry?.id ?? mMessageConfigByLanguageEnglishEntry?.id,
        ingestionEnabled: mMessageConfigByLanguageEntry?.ingestionEnabled ?? (mMessageConfigByLanguageEnglishEntry?.ingestionEnabled ?? false),
        ertAllowed: mMessageConfigByLanguageEntry?.ertAllowed ?? (mMessageConfigByLanguageEnglishEntry?.ertAllowed ?? false),
        canStartPsps: mMessageConfigByLanguageEntry?.canStartPsps ?? (mMessageConfigByLanguageEnglishEntry?.canStartPsps ?? false),
        status: mMessageConfigByLanguageEntry?.status ?? (mMessageConfigByLanguageEnglishEntry?.status ?? false),

        // fields editable in non-English languages won't default to English's values
        trackerText: mMessageConfigByLanguageEntry?.trackerText
      });
    }
  }

  changeSelectedLanguage(event: MatSelectChange) {
    this.selectedLanguage = event?.value;

    // wait a tick after rendering the selected language's form before programatically disabling/enabling
    // mat-select dropdowns and mat-slide-toggle switches
    // otherwise the controls will consider themselves to be disabled/enabled but in the UI they'll still be the opposite
    // (due to the previous form's rendering) due to some internal ordering in the the way Angular
    // renders the mat-select dropdowns
    setTimeout(() => {
      const newlySelectedLanguageFormControls = this.mMessageForms[this.selectedLanguage]?.controls;

      if (this.selectedLanguage !== this.englishLanguage) {
        newlySelectedLanguageFormControls.ingestionEnabled.disable();
        newlySelectedLanguageFormControls.ertAllowed.disable();
        newlySelectedLanguageFormControls.canStartPsps.disable();
        newlySelectedLanguageFormControls.status.disable();
      } else {
        newlySelectedLanguageFormControls.ingestionEnabled.enable();
        newlySelectedLanguageFormControls.ertAllowed.enable();
        newlySelectedLanguageFormControls.canStartPsps.enable();
        newlySelectedLanguageFormControls.status.enable();
      }
    }, 1);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  verifyRequiredFields(): void {
    this.mMessageForms[this.selectedLanguage].markAllAsTouched();
    if (this.mMessageForms[this.selectedLanguage].valid) {
      this.saveMMessage();
    }
  }

  saveMMessage() {
    // if saving English, then ensure we update the fields (in both the form and backend) that are only allowed
    // to be modified in English on all the existing other language entries as well
    if (this.selectedLanguage === this.englishLanguage) {
      const groupOfCalls: Observable<MMessageConfig>[]  = [];

      for (let langOptions of this.languageOptions) {
        // update field values for non-English form with the values from the English form
        // that can only be edited in English in order to keep them in sync
        // we want to do this regardless of whether to not it's used to form the web request payload to the backend
        if (langOptions.value !== this.englishLanguage) {
          this.updateNonEnglishFormsWithEnglishFieldValues(langOptions.value);
        }

        // nothing to sync/update to the backend if it does not yet exist
        if (!this.languageEntryExists[langOptions.value]) {
          continue;
        }

        const mMessageRequestParam: PutMMessageConfigRequest = this.generateMMessageRequestUpdateParam(langOptions.value);

        groupOfCalls.push(
          this.affectedAddressService.updateMMessageConfig(
            langOptions.value, this.mMessageForms[langOptions.value]?.value.id, mMessageRequestParam
          )
        );
      }

      combineLatest(groupOfCalls).subscribe({
        next: responses => {
          this.snackBarService.showSuccessSnackBar();
          this.data.refresh();
        }, error: err => {
          this.snackBarService.showErrorSnackBar(err.message);
          this.data.refresh();
        }
      });
    } else {
      if (!this.languageEntryExists[this.selectedLanguage]) {
        const mMessageRequestParam: PostMMessageConfigRequest = this.generateMMessageRequestCreateParam(this.selectedLanguage);

        this.affectedAddressService.addMMessageConfig(mMessageRequestParam).subscribe({
          next: response => {
            this.languageEntryExists[this.selectedLanguage] = true;

            this.snackBarService.showSuccessSnackBar();
            this.data.refresh();
          }, error: err => {
            this.snackBarService.showErrorSnackBar(err.message);
            this.data.refresh();
          }
        });
      } else {
        const mMessageRequestParam: PutMMessageConfigRequest = this.generateMMessageRequestUpdateParam(this.selectedLanguage);

        this.affectedAddressService.updateMMessageConfig(this.selectedLanguage, this.mMessageForms[this.selectedLanguage].value.id, mMessageRequestParam).subscribe({
          next: response => {
            this.snackBarService.showSuccessSnackBar();
            this.data.refresh();
          }, error: err => {
            this.snackBarService.showErrorSnackBar(err.message);
            this.data.refresh();
          }
        });
      }
    }
  }

  private updateNonEnglishFormsWithEnglishFieldValues(language: string) {
    const englishFormRawValues = this.mMessageForms[this.englishLanguage].getRawValue();

    // the only fields that are allowed to be modified in other languages
    const nonEnglishTrackerText = this.mMessageForms[language].value.trackerText;

    this.mMessageForms[language].setValue({
      id: englishFormRawValues.id,
      ingestionEnabled: englishFormRawValues.ingestionEnabled,
      ertAllowed: englishFormRawValues.ertAllowed,
      canStartPsps: englishFormRawValues.canStartPsps,
      status: englishFormRawValues.status,
      trackerText: nonEnglishTrackerText,
    });
  }

  private generateMMessageRequestUpdateParam(language: string): PutMMessageConfigRequest {
    const mMessageConfigByLanguageFormValues = this.mMessageForms[language]?.getRawValue();

    return {
      ingestionEnabled: mMessageConfigByLanguageFormValues.ingestionEnabled,
      ertAllowed: mMessageConfigByLanguageFormValues.ertAllowed,
      canStartPsps: mMessageConfigByLanguageFormValues.canStartPsps,
      status: mMessageConfigByLanguageFormValues.status,
      trackerText: mMessageConfigByLanguageFormValues.trackerText || '',
    };
  }

  private generateMMessageRequestCreateParam(language: string): PostMMessageConfigRequest {
    const mMessageConfigByLanguageFormValues = this.mMessageForms[language]?.getRawValue();

    return {
      id: mMessageConfigByLanguageFormValues.id,
      language,
      ingestionEnabled: mMessageConfigByLanguageFormValues.ingestionEnabled,
      ertAllowed: mMessageConfigByLanguageFormValues.ertAllowed,
      canStartPsps: mMessageConfigByLanguageFormValues.canStartPsps,
      status: mMessageConfigByLanguageFormValues.status,
      trackerText: mMessageConfigByLanguageFormValues.trackerText || '',
    };
  }

  determineDisableSaveButton() {
    return !this.mMessageForms[this.selectedLanguage].valid;
  }
}
