import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PostMMessageConfigRequest, AffectedAddressStatus } from '../../api/affected-address-api-client.service';
import { AffectedAddressService } from '../../core/services/affected-address.service';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';
import { ConfirmationModalService } from '../../shared/components/confirmation-modal/confirmation-modal.service';
import { ConfirmationModalType } from '../../shared/components/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';
import { DefaultLanguage, Languages } from 'src/app/shared/constants/language';
import { EditMMessageComponent } from '../edit-mmessage-modal/edit-mmessage-modal.component';


@Component({
	selector: 'app-add-mmessage',
	templateUrl: './add-mmessage-modal.component.html',
	styleUrls: ['./add-mmessage-modal.component.css']
})

export class AddMMessageComponent implements OnInit {
  mMessageForm: FormGroup;
  mMessageStatusList: string[] =  Object.values(AffectedAddressStatus);

  mMessageAdd: PostMMessageConfigRequest;

  status: string;

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private router: Router,
    private affectedAddressService: AffectedAddressService,
    public dialogRef: MatDialogRef<AddMMessageComponent>,
    private confirmationModalService: ConfirmationModalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.mMessageForm = this.formBuilder.group({
      id: null,
      ingestionEnabled: false,
      ertAllowed: false,
      canStartPsps: false,
      status: null,
      trackerText: null
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  verifyRequiredFields(): void {
    this.mMessageForm.markAllAsTouched();
    if (this.mMessageForm.valid) {
      this.saveMMessage();
    }
  }

  saveMMessage() {
    this.mMessageAdd = {
      id: this.mMessageForm.value.id,
      language: Languages.English,
      ingestionEnabled: this.mMessageForm.value.ingestionEnabled || false,
      ertAllowed: this.mMessageForm.value.ertAllowed || false,
      canStartPsps: this.mMessageForm.value.canStartPsps || false,
      status: this.mMessageForm.value.status,
      trackerText: this.mMessageForm.value.trackerText || "",
    };

    this.affectedAddressService.addMMessageConfig(this.mMessageAdd).subscribe({
      next: response => {
        this.snackBarService.showSuccessSnackBar();
        this.data.refresh();
        this.dialogRef.close();
        this.openSuccessConfirmationModal();
      }, error: err => {
        console.log(err);
        this.snackBarService.showErrorSnackBar(err.data);
        this.data.refresh();
        this.dialogRef.close();
      }
    });
  }

  async openSuccessConfirmationModal(): Promise<any> {
    const afterConfirmed = await this.confirmationModalService.openConfirmationModal(ConfirmationModalType.addMMessageSuccess, null, null);

    if (afterConfirmed?.action === 'primary') {
      this.dialog.open(EditMMessageComponent, {
        height: 'auto',
        autoFocus: false,
        disableClose: false,
        data: {
          mMessageConfigByLanguage: {
            [Languages.English]: {
              id: this.mMessageForm.value.id,
              language: Languages.English,
              ingestionEnabled: this.mMessageForm.value.ingestionEnabled,
              ertAllowed: this.mMessageForm.value.ertAllowed,
              canStartPsps: this.mMessageForm.value.canStartPsps,
              status: this.mMessageForm.value.status,
              trackerText: this.mMessageForm.value.trackerText
            },
          },
          refresh: this.data.refresh
        }
      });
    } else {
      const afterConfirmed = await this.confirmationModalService.openConfirmationModal(ConfirmationModalType.addMMessageSuccessAlternative, null, null);

      if (afterConfirmed?.action === 'primary') {
        // route to push notification settings add modal
        this.router.navigate(['/push-notification-content'], {queryParams: {id: this.mMessageAdd.id}});
      }
    }
  }

  determineDisableSaveButton() {
    return !this.mMessageForm.valid;
  }
}
