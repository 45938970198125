import { FormGroup } from "@angular/forms";
import { LanguageNames, Languages } from "../../constants/language";

export const languageDisplayOptions = [{
  name: 'English',
  value: Languages.English
},
{
  name: 'Spanish',
  value: Languages.Spanish
}];

export function concatenateLanguageKeys(objectByLanguage) {
  const languagesOnElement = Object.keys(objectByLanguage);

  return languagesOnElement.map(x => LanguageNames[x]).join(', ');
}

export function isObjectKeysMissingLanguages(objectByLanguage) {
  const languagesOnElement = Object.keys(objectByLanguage);
  const languageNames = Object.keys(LanguageNames);

  return languagesOnElement?.length !== languageNames?.length;
}
