import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MMessageConfig } from '../api/affected-address-api-client.service';
import { catchError, combineLatest, EMPTY, map, Observable, Subscription } from 'rxjs';
import { SnackBarService } from '../shared/components/snackbar/snackbar.service';
import { AffectedAddressService } from '../core/services/affected-address.service';
import { NotificationService } from '../core/services/notification.service';
import { EditMMessageComponent } from './edit-mmessage-modal/edit-mmessage-modal.component';
import { AddMMessageComponent } from './add-mmessage-modal/add-mmessage-modal.component';
import { ConfirmationModalService } from '../shared/components/confirmation-modal/confirmation-modal.service';
import { ConfirmationModalType } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { DefaultLanguage, Languages } from '../shared/constants/language';
import { MMessageConfigByLanguage } from './models/mmessage-config-by-language.model';
import { concatenateLanguageKeys, isObjectKeysMissingLanguages } from '../shared/utils/multilanguage/multilanguage-helpers.util';
import { DeletePushNotificationContentResponse } from '../api/notification-api-client.service';

@Component({
  selector: 'app-mmessage',
  templateUrl: './mmessage.component.html',
  styleUrls: ['./mmessage.component.css']
})
export class MmessageComponent implements OnInit, OnDestroy {
  public mMessageConfigsList = new MatTableDataSource<MMessageConfigByLanguage>();

  public isLoading = false;

  public displayedColumns = [
    'Id',
    'IngestionEnabled',
    'CanStartPSPS',
    'ERTAllowed',
    'Status',
    'TrackerText',
    'LastUpdated',
    'Languages',
    'Edit',
    'Delete'
  ];

  public readonly tooltips = {
    id: "Unique ID for the message",
    ingestionEnabled: "When set to true, the app will act on this message if it is received as incoming data",
    canStartPsps: "When set to true, this message can initiate a new PSPS event",
    ertAllowed: "When set to true, Estimated Restoration Time information will be published in the app for this message",
    status: "Event status associated with this M-Message",
    trackerText: "Message text that will be published on the app for this message",
    missingLanguageTooltipMessage: 'Not all available languages have been configured'
  };

  public mainLanguage = DefaultLanguage;

  private subscription: Subscription;

  constructor(
    private affectedAddressService: AffectedAddressService,
    private notificationService: NotificationService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    this.getMMessageConfigs();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  parseMId(id: string): number {
    try {
      return parseInt(id.match(/\d+/)[0]);
    } catch(err) {
      return 0;
    }
  }

  getMMessageConfigs(): void {
    this.subscription = this.affectedAddressService.getMMessageConfig().pipe(
      catchError(e => {
        this.snackBarService.showErrorSnackBar(e.message);
        console.error(e);
        this.mMessageConfigsList.data = [];
        this.isLoading = false;
        return EMPTY;
      }),
      map(res => res.result)
    ).subscribe( res => {
      this.mMessageConfigsList.data = this.getOrderedTableData(res);

      this.isLoading = false;
    });
  }

  openEditModal(mMessageConfigByLanguage: MMessageConfigByLanguage) {
    this.dialog.open(EditMMessageComponent, {
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: {
        mMessageConfigByLanguage,
        refresh: () => this.getMMessageConfigs()
      }
    });
  }

  openAddModal() {
    this.dialog.open(AddMMessageComponent, {
      height: 'auto',
      autoFocus: false,
      disableClose: false,
      data: {
        refresh: () => this.getMMessageConfigs()
      }
    });
  }

  getOrderedTableData(data: MMessageConfig[]): MMessageConfigByLanguage[] {
    const dataByLanguage = {};

    for (let dataItem of data) {
      if (!dataByLanguage[dataItem.id]) {
        dataByLanguage[dataItem.id] = {};
      }

      dataByLanguage[dataItem.id][dataItem.language] = dataItem;
    }

    const idKeys = Object.keys(dataByLanguage);
    idKeys.sort((a, b) => this.parseMId(a) - this.parseMId(b) || a.charCodeAt(a.length-1) - b.charCodeAt(b.length-1));;

    const tableData = [];

    for (let idKey of idKeys) {
      tableData.push(dataByLanguage[idKey]);
    }

    return tableData;
  }

  async deleteMMessage(mMessageConfigByLanguage: MMessageConfigByLanguage) {
    const afterConfirmed = await this.confirmationModalService.openConfirmationModal(
      ConfirmationModalType.deleteMMessage,
      null,
      mMessageConfigByLanguage[Languages.English]?.id
    );

    if (afterConfirmed?.action === 'primary') {
      const langKeys = Object.keys(mMessageConfigByLanguage);
      const groupOfCalls: Observable<void>[]  = [];

      for (let lang of langKeys) {
        groupOfCalls.push(this.affectedAddressService.deleteMMessageConfig(lang, mMessageConfigByLanguage[lang]?.id));
      }

      combineLatest(groupOfCalls).subscribe({
        next: () => {
          this.snackBarService.showSuccessSnackBar();
          this.getMMessageConfigs();
        }, error: err => {
          this.snackBarService.showErrorSnackBar(err.message);
          this.getMMessageConfigs();
        }
      });

      // try to delete associated push notification content
      this.notificationService.getAllPushNotificationContent().pipe(
        map(res => res.result))
        .subscribe({
          next: result => {
            // "id" is the same across all languages, so we can just reference the main language's (English)
            const filteredResult = result.filter(item => item.id === mMessageConfigByLanguage[Languages.English]?.id);

            const groupOfCalls: Observable<DeletePushNotificationContentResponse>[]  = [];

            filteredResult.forEach((result) => {
              // check if id exists and delete push notification
              groupOfCalls.push(this.notificationService.deletePushNotificationContent(result.language, mMessageConfigByLanguage[Languages.English]?.id))
            });

            combineLatest(groupOfCalls).subscribe({
              next: response => {
                console.log(response);
              },
              error: err => {
                console.log(err);
              }
            });
          },
          error: err => {
            console.log(err);
          }
      });
    }
  }

  displayLanguagesForRow(mMessageConfigByLanguage: MMessageConfigByLanguage) {
    return concatenateLanguageKeys(mMessageConfigByLanguage);
  }

  displayMissingLanguageTooltip(mMessageConfigByLanguage: MMessageConfigByLanguage) {
    return isObjectKeysMissingLanguages(mMessageConfigByLanguage);
  }
}
