<div class="modal-container">
  <button mat-button class="close-button" (click)="emitActionSelected('close')">
    <mat-icon>close</mat-icon>
  </button>
  <div class="center-align">
    <mat-icon class="warning">error_outline</mat-icon>
    <p [innerHTML]="modal.header"></p>
    <mat-dialog-content [innerHTML]="modal.content"></mat-dialog-content>
    <ng-container *ngIf="isMMessageUpdate">
      <mat-dialog-content>
        <div fxLayoutAlign="end">
          <mat-label>
            {{ pushNotificationTogglePosition ? 'Push notifications will be sent' : 'Push notifications will not be
            sent' }}
          </mat-label>
          <mat-slide-toggle #pushNotificationToggle color="accent" [(ngModel)]="pushNotificationTogglePosition"
            [disabled]="!pushNotificationTogglePosition"></mat-slide-toggle>
        </div>
      </mat-dialog-content>
    </ng-container>
    <mat-dialog-actions>
      <button id="QA-Confirmation-Modal-SecondaryButton" mat-flat-button color="outline" [mat-dialog-close]="true"
        (click)="emitActionSelected('secondary')" fxFlex>
        <span>{{ modal.outlineButtonLabel }}</span>
      </button>
      <button id="QA-Confirmation-Modal-PrimaryButton" mat-flat-button color="primary" [mat-dialog-close]="true"
        (click)="emitActionSelected('primary')" fxFlex>
        <span>{{ modal.primaryButtonLabel }}</span>
      </button>
    </mat-dialog-actions>
  </div>
</div>