import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat, first } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DefaultLanguage } from '../../constants/language';


export enum ConfirmationModalType {
  deactivate = 'deactivate',
  updateErt = 'updateErt',
  clearErt = 'clearErt',
  updateMMessage = 'updateMMessage',
  addMMessageSuccess = 'addMMessageSuccess',
  addMMessageSuccessAlternative = 'addMMessageSuccessAlternative',
  deleteMMessage = 'deleteMMessage',
  addDuplicate = 'addDuplicate',
  replace = 'replace',
  pspsEventEnd = 'pspsEventEnd',
  toggleNotifications = 'toggleNotifications',
  deleteCrc = 'deleteCrc',
  toggleAppStatus = 'toggleAppStatus',
  sendAdHocNotification = 'sendAdHocNotification',
  adHocNotificationRequestSent = "adHocNotificationRequestSent",
  addPushNotificationSuccess = 'addPushNotificationSuccess',
  addNewCrcSuccess = 'addNewCrcSuccess',
}

export class ConfirmationModalData {
  modalType: ConfirmationModalType;
  selectedRows: number;
  value: string;
}

export class ConfirmationModalCloseResponse {
  action: string;
  isNotificationEnabled: boolean;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  public modal: any;

  public pushNotificationTogglePosition: boolean;
  public isMMessageUpdate: boolean = false;
  public selectedRows: string = '';
  public value: string = '';
  public estimatedRestorationTime: string = '';
  public message: string = '';
  public header: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData) { }

  ngOnInit() {
    this.selectedRows = this.data.selectedRows > 1 ? `${this.data.selectedRows} records` : `${this.data.selectedRows} record`;
    this.value = this.data.value;
    if (this.data.modalType === ConfirmationModalType.updateMMessage) {
      this.handleMMessageParams();
    } else if (this.data.modalType === ConfirmationModalType.updateErt) {
      this.formatErt();
    } else if (this.data.modalType === ConfirmationModalType.toggleNotifications) {
      this.message = this.value === 'On' ?
        `This action will resume notifications until they are turned back off or the event ends.` :
        `This action will suspend notifications until they are turned back on. If you want to manage notifications for specific locations,
        please use the Affected Address Management tool.`;
    } else if (this.data.modalType === ConfirmationModalType.toggleAppStatus) {
      this.message = this.value === 'On' ?
      `This will re-enable the application for all users` :
      `This will disable the application for all users until the toggle is re-enabled`;
      this.header = this.value === 'On' ?
      `Re-enable the application for users?` : `Notify all users the application is down?`;
    } else if (this.data.modalType === ConfirmationModalType.sendAdHocNotification) {
      this.header = this.selectedRows === '1 record' ?
      `Send your message to the record selected?` :
      `Send your message to the ${this.selectedRows} selected?`;
    }

    const modalTypes = {
      deactivate: {
        header: `Mark ${this.selectedRows} as inactive?`,
        content: `Please make a selection.
        Marking inactive for the entire event will remove the record from subsquent files.
        Marking inactive once will remove the record from the current file only.`,
        outlineButtonLabel: 'Mark Inactive for Entire Event',
        primaryButtonLabel: 'Mark Inactive Once'
      },
      updateErt: {
        header: 'Change Estimated Restoration Time (ERT)?',
        content: `<span>Warning:</span>
        Are you sure you want to change the Estimated Restoration Time for
        ${this.selectedRows} to ${this.estimatedRestorationTime} PT?`,
        outlineButtonLabel: 'No, Do Not Change',
        primaryButtonLabel: 'Yes, Change'
      },
      clearErt: {
        header: 'Clear Estimated Restoration Time (ERT)?',
        content: `<span>Warning:</span>
        Are you sure you want to clear the Estimated Restoration Time for ${this.selectedRows}?
        Doing so will default to the ERT contained in the outage.json.`,
        outlineButtonLabel: 'No, Do Not Clear',
        primaryButtonLabel: 'Yes, Clear'
      },
      updateMMessage: {
        header: 'Change M-Message?',
        content: `<span>Warning:</span>
        Are you sure you want to move ${this.selectedRows} to ${this.value}?`,
        outlineButtonLabel: 'No, Do Not Change',
        primaryButtonLabel: 'Yes, Change M-Message'
      },
      addMMessageSuccess: {
        header: `Success! Do you want to configure additional languages for this M-Message?`,
        content: `<span>Warning:</span>
        Push Notifications will not be sent on new M-Message if it is not configured in the Push Notification Settings tab.
        Ensure that you configure them later if you are proceeding to add additional languages now.`,
        outlineButtonLabel: 'No, Ask About Push Notifications',
        primaryButtonLabel: 'Yes, Add More Translations'
      },
      addMMessageSuccessAlternative: {
        header: `Continue to Push Notification Settings?`,
        content: `<span>Warning:</span>
        Push Notifications will not be sent on new M-Message if it is not configured in the Push Notification Settings tab.`,
        outlineButtonLabel: 'No, Thanks',
        primaryButtonLabel: 'Yes, Continue'
      },
      deleteMMessage: {
        header: `Delete M-Message: ${this.value}?`,
        content: `<span>Warning:</span>
        Are you sure you want to delete M-Message: ${this.value}? This action is permanent and cannot be undone.`,
        outlineButtonLabel: 'No, Do Not Delete',
        primaryButtonLabel: 'Yes, Delete M-Message'
      },
      addDuplicate: {
        header: 'Address Associated with Multiple IDs',
        content: `This address is already part of the current event but is associated with one or more
        deviceIDs. The status of ${this.value} may be overridden by another address/deviceID pair.`,
        outlineButtonLabel: 'Cancel',
        primaryButtonLabel: 'Add Anyway'
      },
      replace: {
        header: 'Replace and Remove from Invalid Address List?',
        content: `<span>Warning:</span>
        Replacing ${this.selectedRows} will remove them from the bad address list.
        All future addresses that match the selected bad addresses will be corrected to ${this.value}`,
        outlineButtonLabel: 'No, Go Back',
        primaryButtonLabel: 'Yes, Replace and Remove'
      },
      pspsEventEnd: {
        header: 'Turn Off Public Safety Power Shutoff',
        content: `<span>Warning:</span>
        Are you sure you want to take this action?
        Ending the PSPS event will reset all outage trackers to No PSPS Outage.`,
        outlineButtonLabel: 'No, Do Not Turn Off PSPS',
        primaryButtonLabel: 'Yes, Turn Off PSPS'
      },
      toggleNotifications: {
        header: `Turn ${this.value} Push Notifications?`,
        content: `<span>Warning:</span>
        ${this.message}`,
        outlineButtonLabel: 'No, Go Back',
        primaryButtonLabel: `Yes, Turn ${this.value}`
      },
      deleteCrc: {
        header: `Delete CRC?`,
        content: `<span>Warning:</span>
        Are you sure you want to delete ${this.value}? This action is permanent and cannot be undone.`,
        outlineButtonLabel: 'No, Go Back',
        primaryButtonLabel: `Yes, Delete CRC`
      },
      toggleAppStatus: {
        header: `${this.header}`,
        content: `<span>Warning:</span>
        ${this.message}`,
        outlineButtonLabel: 'No, Go Back',
        primaryButtonLabel: `Yes`
      },
      sendAdHocNotification: {
        header: `${this.header}`,
        content: `<span>Warning:</span>
        All mobile devices associated with the selected addresses will receive a push notification.`,
        outlineButtonLabel: 'No, Cancel',
        primaryButtonLabel: `Yes, Send`
      },
      adHocNotificationRequestSent: {
        header: `Your request has been sent.`,
        content: `<span>Warning:</span>
        Please allow up to an hour for this process to complete.`,
        outlineButtonLabel: 'Exit',
        primaryButtonLabel: 'Okay'
      },
      addPushNotificationSuccess: {
        header: `Do you want to configure additional languages for this push notification?`,
        outlineButtonLabel: 'No, I Will Configure Later',
        primaryButtonLabel: 'Yes, Add More Translations'
      },
      addNewCrcSuccess: {
        header: `Do you want to configure additional languages for this CRC?`,
        outlineButtonLabel: 'No, I Will Configure Later',
        primaryButtonLabel: 'Yes, Add More Translations'
      }
    };

    this.modal = modalTypes[this.data.modalType];
  }

  formatErt() {
    this.estimatedRestorationTime = this.datePipe.transform(this.value, 'MM/dd/yy \' at \' h:mm a');
  }

  async handleMMessageParams() {
    this.isMMessageUpdate = true;

    // Check both the global toggle and the m-message level selected
    concat(
      this.notificationService.isNotificationEnabled(DefaultLanguage, this.data.value),
      this.notificationService.isNotificationEnabled(DefaultLanguage, 'All')
    ).pipe(first()).subscribe(notificationsEnabled => {
      this.pushNotificationTogglePosition = notificationsEnabled;
    });
  }

  public emitActionSelected(action: string) {
    const dialogCloseParams: ConfirmationModalCloseResponse = {
      action,
      isNotificationEnabled: this.pushNotificationTogglePosition
    };
    this.dialogRef.close(dialogCloseParams);
  }
}
