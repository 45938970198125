import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fileSource'})
export class FileSourcePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'outage':
        return 'Outage.JSON';
      default:
        return 'M-Message.JSON';
    }
  }
}
