import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AddPushNotificationRequest, UpdatePushNotificationContentResponse, UpdatePushNotificationRequest } from 'src/app/api/notification-api-client.service';
import { MatSelectChange } from '@angular/material/select';
import { DefaultLanguage, Languages } from 'src/app/shared/constants/language';
import { PushNotificationContentByLanguage } from '../models/push-notification-content-by-language.model';
import { languageDisplayOptions } from 'src/app/shared/utils/multilanguage/multilanguage-helpers.util';
import { combineLatest, Observable } from 'rxjs';

@Component({
	selector: 'app-edit-push-notification-content',
	templateUrl: './edit-push-notification-content-modal.component.html',
	styleUrls: ['./edit-push-notification-content-modal.component.css']
})

export class EditPushNotificationContentComponent implements OnInit {

  pushNotificationUpdate: UpdatePushNotificationRequest = {
    sendPushNotification: false,
    title: "",
    body: ""
  };

  pushnotificationcontentId: string = '';

  englishLanguage = Languages.English;
  selectedLanguage = this.englishLanguage;

  languageOptions = languageDisplayOptions;
  languageEntryExists = {};

  pushNotificationContentForms: { [language: string]: FormGroup } = {};

  public readonly titleTooltip = "Push notification title is limited to 40 characters.";
  public readonly bodyTooltip = "Push notification body is limited to 150 characters.";
  public readonly onlyEnglishEditableTooltip = 'This field applies across all languages and can only be edited when English is selected.'
  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<EditPushNotificationContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    for (let langOptions of this.languageOptions) {
      const lang = langOptions.value;
      const pushNotificationContentByLanguageEntry = this.data.pushNotificationContentByLanguage?.[lang];
      const pushNotificationContentByLanguageEnglishEntry = this.data.pushNotificationContentByLanguage?.[this.englishLanguage];
      this.pushnotificationcontentId = pushNotificationContentByLanguageEnglishEntry?.id;

      this.languageEntryExists[lang] = pushNotificationContentByLanguageEntry != null;

      this.pushNotificationContentForms[lang] = this.formBuilder.group({
        id: pushNotificationContentByLanguageEntry?.id || pushNotificationContentByLanguageEnglishEntry?.id,
        title: pushNotificationContentByLanguageEntry?.title,
        notificationsEnabled: pushNotificationContentByLanguageEntry?.sendPushNotification ?? (pushNotificationContentByLanguageEnglishEntry?.sendPushNotification ?? false),
        body: pushNotificationContentByLanguageEntry?.body
      });
    }
  }

  changeSelectedLanguage(event: MatSelectChange) {
    this.selectedLanguage = event?.value;

    setTimeout(() => {
      const newlySelectedLanguageFormControls = this.pushNotificationContentForms[this.selectedLanguage]?.controls;

      if (this.selectedLanguage !== this.englishLanguage) {
        newlySelectedLanguageFormControls.notificationsEnabled.disable();
      } else {
        newlySelectedLanguageFormControls.notificationsEnabled.enable();
      }
    }, 1);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  verifyRequiredFields(): void {
    this.pushNotificationContentForms[this.selectedLanguage].markAllAsTouched();
    if (this.pushNotificationContentForms[this.selectedLanguage].valid) {
      this.savePushNotificationContent();
    }
  }

  savePushNotificationContent() {
    // if saving English, then ensure we update the fields (in both the form and backend) that are only allowed
    // to be modified in English on all the existing other language entries as well
    if (this.selectedLanguage === this.englishLanguage) {
      const groupOfCalls: Observable<UpdatePushNotificationContentResponse>[]  = [];

      for (let langOptions of this.languageOptions) {
        // update field values for non-English form with the values from the English form
        // that can only be edited in English in order to keep them in sync
        // we want to do this regardless of whether to not it's used to form the web request payload to the backend
        if (langOptions.value !== this.englishLanguage) {
          this.updateNonEnglishFormsWithEnglishFieldValues(langOptions.value);
        }

        // nothing to sync/update to the backend if it does not yet exist
        if (!this.languageEntryExists[langOptions.value]) {
          continue;
        }

        const requestParam: UpdatePushNotificationRequest = this.generatePushNotificationRequestUpdateParam(langOptions.value);

        groupOfCalls.push(
          this.notificationService.updatePushNotificationContent(langOptions.value, this.pushNotificationContentForms[langOptions.value]?.value.id, requestParam)
        );
      }

      combineLatest(groupOfCalls).subscribe({
        next: responses => {
          this.snackBarService.showSuccessSnackBar();
          this.data.refresh();
        }, error: err => {
          this.snackBarService.showErrorSnackBar(err.message);
          this.data.refresh();
        }
      });
    } else {
      if (!this.languageEntryExists[this.selectedLanguage]) {
        const requestParam: AddPushNotificationRequest = this.generatePushNotificationRequestCreateParam(this.selectedLanguage);

        this.notificationService.addPushNotificationContent(requestParam).subscribe({
          next: response => {
            this.languageEntryExists[this.selectedLanguage] = true;

            this.snackBarService.showSuccessSnackBar();
            this.data.refresh();
          }, error: err => {
            this.snackBarService.showErrorSnackBar(err.message);
            this.data.refresh();
          }
        });
      } else {
        const requestParam: UpdatePushNotificationRequest = this.generatePushNotificationRequestUpdateParam(this.selectedLanguage);

        this.notificationService.updatePushNotificationContent(this.selectedLanguage, this.pushNotificationContentForms[this.selectedLanguage]?.value.id, requestParam).subscribe({
          next: response => {
            this.snackBarService.showSuccessSnackBar();
            this.data.refresh();
          }, error: err => {
            this.snackBarService.showErrorSnackBar(err.message);
            this.data.refresh();
          }
        });
      }
    }
  }

  private generatePushNotificationRequestUpdateParam(language: string): UpdatePushNotificationRequest {
    const formValues = this.pushNotificationContentForms[language]?.getRawValue();

    return {
      sendPushNotification: formValues?.notificationsEnabled,
      title: formValues?.title,
      body: formValues?.body
    }
  }

  private generatePushNotificationRequestCreateParam(language: string): AddPushNotificationRequest {
    const formValues = this.pushNotificationContentForms[language]?.getRawValue();

    return {
      id: formValues?.id,
      language: this.selectedLanguage,
      sendPushNotification: formValues?.notificationsEnabled,
      title: formValues?.title,
      body: formValues?.body
    };
  }

  private updateNonEnglishFormsWithEnglishFieldValues(language: string) {
    const englishFormRawValues = this.pushNotificationContentForms[this.englishLanguage].getRawValue();

    // the fields that are allowed to be modified in other languages
    const nonEnglishTitle = this.pushNotificationContentForms[language].value.title;
    const nonEnglishBody = this.pushNotificationContentForms[language].value.body;

    this.pushNotificationContentForms[language].setValue({
      id: englishFormRawValues.id,
      title: nonEnglishTitle,
      body: nonEnglishBody,
      notificationsEnabled: englishFormRawValues.notificationsEnabled
    });
  }

  determineDisableSaveButton() {
    return !this.pushNotificationContentForms[this.selectedLanguage].valid;
  }
}
