import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapService } from './services/map.service';
import { AffectedAddressService } from './services/affected-address.service';
import { GeocodeService } from './services/geocode.service';
import { NotificationService } from './services/notification.service';
import { UserIdleModule } from 'angular-user-idle';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserIdleModule
  ],
  providers: [
    MapService,
    AffectedAddressService,
    GeocodeService,
    NotificationService
  ]
})
export class CoreModule { }
