import { Injectable } from '@angular/core';
import { AppConfig } from './app-config.model';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  config: AppConfig;

  constructor(
    // Config request needs to bypass the HTTP interceptor, so performing this request using HttpBackend
    private httpBackend: HttpBackend,
    private http: HttpClient) { }

  async load(): Promise<AppConfig> {

    this.http = new HttpClient(this.httpBackend);

    return this.http
      .get<AppConfig>('../assets/config.json')
      .toPromise()
      .then(data => {
        return (this.config = data);
      });
  }
}
