<nav class="navbar navbar-expand-md fixed-top">
  <div class="sdge-logo">
    <a href="/home">
      <img src="assets/icons/sdge-white.png">
    </a>
  </div>
  <a class="navbar-brand" href="/home">PSPS MOBILE MANAGEMENT PORTAL</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse" *ngIf="isLoggedIn$ | async">
    <div class="navbar-nav mr-auto">
      <mat-tab-group animationDuration="0ms" disableRipple [attr.data-automation]="'QE-Header-Parent-Tab-Container'">
        <mat-tab label="Event Management" id="QA-TopBar-EventManagementTab">
          <div id="event-mgmt-triangle" class="event-triangle-up"></div>
          <div class="navbar-submenu fixed-top">
            <ul class="navbar-nav mr-auto" [attr.data-automation]="'QE-Header-Child-Tab-Container'">
              <li class="nav-item" *ngFor="let item of eventMenuItems">
                <a class="nav-link nav-link-submenu" [routerLink]="item.path" routerLinkActive="active-link"
                  #rla="routerLinkActive" [attr.data-automation]="'QE-Header-Child-Tab-Button'">{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="Data Management" id="QA-TopBar-DataManagementTab">
          <div id="data-mgmt-triangle" class="data-triangle-up"></div>
          <div class="navbar-submenu fixed-top">
            <ul class="navbar-nav mr-auto" [attr.data-automation]="'QE-Header-Child-Tab-Container'">
              <li class="nav-item" *ngFor="let item of dataMenuItems">
                <a class="nav-link nav-link-submenu" [routerLink]="item.path" routerLinkActive="active-link"
                  #rla="routerLinkActive" [attr.data-automation]="'QE-Header-Child-Tab-Button'">{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </mat-tab>
        <mat-tab label="Rules & Settings" id="QA-TopBar-RulesAndSettingsTab">
          <div id="rules-settings-triangle" class="rules-triangle-up"></div>
          <div class="navbar-submenu fixed-top">
            <ul class="navbar-nav mr-auto" [attr.data-automation]="'QE-Header-Child-Tab-Container'">
              <li class="nav-item" *ngFor="let item of rulesMenuItems">
                <a class="nav-link nav-link-submenu" [routerLink]="item.path" routerLinkActive="active-link"
                  #rla="routerLinkActive" [attr.data-automation]="'QE-Header-Child-Tab-Button'">{{ item.label }}</a>
              </li>
            </ul>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div *ngIf="isLoggedIn$ | async">
    <button mat-flat-button class="nav-logout" (click)="logOut()">Log Out</button>
  </div>
</nav>