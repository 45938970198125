import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BadAddressesComponent } from './bad-address.component';
import { ReplaceAddressModalComponent } from './replace-address-modal/replace-address-modal.component';
import { BadAddressFiltersComponent } from './bad-address-filters/bad-address-filters.component';

@NgModule({
  declarations: [
    BadAddressesComponent,
    ReplaceAddressModalComponent,
    BadAddressFiltersComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([
      { path: '', component: BadAddressesComponent }
    ])
  ]
})

export class BadAddressModule { }
