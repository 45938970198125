<div id = "QA-AddPushNotificationContent-Modal-AddPushNotificationContentModal" [formGroup]="pushNotificationContentForm" class="add-field">
	<div mat-dialog-content>
		<div class="right-align">
			<button mat-button type="button" class="close-button" (click)="closeDialog()">
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div style="padding-top: 10px;">
			<span class="add-pushnotificationcontent-headers" id="QA-PushNotificationContent-Modal">Add Push Notification</span>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field appearance="outline" class="full-length">
					<mat-label id="QA-AddPushNotificationContent-Modal-TypeLabel">Notification Type</mat-label>
					<mat-select id="QA-AddPushNotificationContent-Modal-TypeDropDown" formControlName="id" required>
						<mat-option *ngFor="let type of notificationTypes" [value]="type" id="QA-NotificationType-DropDown-Option">
						{{ type }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="pushNotificationContentForm.get('id').hasError('required')">This field is required.</mat-error>
				</mat-form-field>
			</div>
			<mat-icon color="primary" class="help" matTooltip="{{ notificationTypeTooltip }}" matTooltipPosition="above">help_outline</mat-icon>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field appearance="outline" class="full-length">
					<mat-label id="QA-AddPushNotificationContent-Modal-Title">Title</mat-label>
					<input id="QA-AddPushNotificationContent-Modal-TitleInput" matInput formControlName="title" maxlength="40" required>
					<mat-error *ngIf="pushNotificationContentForm.get('title').hasError('required')">This field is required.</mat-error>
				</mat-form-field>
			</div>
			<mat-icon color="primary" class="help" matTooltip="{{ titleTooltip }}" matTooltipPosition="above"
            	matTooltipClass="pushnotification-title-tooltip">help_outline</mat-icon>
		</div>
		<div class="row">
			<div class="col">
				<mat-form-field appearance="outline" class="full-length">
					<mat-label id="QA-AddPushNotificationContent-Modal-Body">Body</mat-label>
					<input id="QA-AddPushNotificationContent-Modal-BodyInput" matInput formControlName="body" maxlength="150" required>
					<mat-error *ngIf="pushNotificationContentForm.get('body').hasError('required')">This field is required.</mat-error>
				</mat-form-field>
			</div>
			<mat-icon color="primary" class="help" matTooltip="{{ bodyTooltip }}" matTooltipPosition="above">help_outline</mat-icon>
		</div>
		<div class="row" style="padding-left: 15px;">
			<mat-form-field floatLabel="always" appearance="none">
				<mat-slide-toggle formControlName="notificationsEnabled" id="QA-AddPushNotificationContent-Modal-Notifications-Toggle">Notifications Enabled</mat-slide-toggle>
				<textarea matInput hidden></textarea>
			</mat-form-field>
			<mat-icon color="primary" class="help-toggle" matTooltipPosition="above" matTooltip="{{ notificationsEnabledTooltip }}">help_outline</mat-icon>
		</div>
		<div style="padding-bottom: 10px;">
			<mat-dialog-actions align="end">
				<button id="QA-AddPushNotificationContent-Modal-CancelButton" mat-flat-button color="outline" class="add-pushnotificationcontent-dialog-actions" (click)="closeDialog()">Cancel</button>
				<button id="QA-AddPushNotificationContent-Modal-SaveButton" mat-flat-button color="primary" class="add-pushnotificationcontent-dialog-actions" (click)="verifyRequiredFields()">Save</button>
			</mat-dialog-actions>
		</div>
	</div>
</div>