import { MatTableDataSource } from '@angular/material/table';
import { ActiveAddress } from '../../../api/affected-address-api-client.service';
import { Injectable } from '@angular/core';
import { PostAffectedAddressResult } from 'src/app/api/affected-address-api-client.service';
import { BulkUpdateType } from '../../affected-addresses-bulk/affected-addresses-bulk.service';

@Injectable({
  providedIn: 'root'
})
export class TableUpdateService {
  constructor() { }

  updateAddress(
    addresses: MatTableDataSource<ActiveAddress>,
    oldAddress: ActiveAddress,
    updatedAddress: ActiveAddress
  ): void {
    const index = addresses.data.findIndex(a => a.id === oldAddress.id);
    if (index !== -1) {
      addresses.data.splice(index, 1, updatedAddress);
    }
  }

  addAddressToTable(addresses: MatTableDataSource<ActiveAddress>, result: PostAffectedAddressResult): void {
    const newAddress: ActiveAddress = {
      id: result.id,
      address: result.ingestedAddress.address,
      city: result.ingestedAddress.city,
      zip: result.ingestedAddress.postalCode,
      sectionalizationDeviceId: result.sectionalizationDeviceId,
      statusSource: result.statusSource,
      status: result.status,
      addressKey: result.addressKey,
      estimatedRestorationTime: result.estimatedRestorationTime,
      estimatedRestorationTimeSrc: result.estimatedRestorationTimeSrc,
      updatedOn: (Date.parse(result.updatedOn) / 1000).toString()
    };
    addresses.data.unshift(newAddress);
    addresses._updateChangeSubscription();
  }

  updateAllAddresses(
    addresses: MatTableDataSource<ActiveAddress>,
    selectedAddresses: ActiveAddress[],
    property: BulkUpdateType,
    value: string): void {

    selectedAddresses.forEach(address => {
      const index: number = addresses.data.findIndex(a => a.id === address.id);
      if (property === BulkUpdateType.Deactivate) {
        // if deactivating, remove element from the table
        addresses.data.splice(index, 1);
        addresses._updateChangeSubscription();
      } else if (property === BulkUpdateType.EstimatedRestorationTime) {
        if (new Date(value).getTime() === 0) {
          addresses.data[index].estimatedRestorationTimeSrc = 'outage';
        } else {
          addresses.data[index].estimatedRestorationTimeSrc = 'portal';
          addresses.data[index].estimatedRestorationTime = value;
        }
      } else {
        addresses.data[index][property] = value;
      }
    });
    addresses._updateChangeSubscription();
  }
}
