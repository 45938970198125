<div id = "QA-EditMMessage-Modal-EditMMessageModal" [formGroup]="mMessageForm" class="add-field">
	<div mat-dialog-content>
		<div class="right-align">
			<button mat-button type="button" [mat-dialog-close]="true" class="close-button">
				<mat-icon>close</mat-icon>
			</button>
		</div>
		<div class="header-row">
			<span id="QA-Add-Postal-Code-Title" class="add-mmessage-header">Add M-Message</span>
		</div>
		<div class="row" style="padding-top: 20px;">
			<mat-form-field appearance="outline" class="half-length">
				<mat-label>M-Message</mat-label>
				<input id="QA-EditMMessage-Modal-Mmessage-Text-Input" matInput formControlName="id" required>
				<mat-error *ngIf="mMessageForm.get('id').hasError('required')">This field is required.</mat-error>
			</mat-form-field>
			<mat-icon color="primary" class="help" matTooltip="Enter the ID for the new M-Message.">help_outline</mat-icon>
		</div>
		<div style="height:65px" class="row">
			<mat-form-field floatLabel="always" appearance="none">
        <mat-slide-toggle formControlName="ingestionEnabled" id="QA-Add-MMessage-Modal-Ingestion-Toggle">Ingestion Enabled</mat-slide-toggle>
        <textarea matInput hidden></textarea>
			</mat-form-field>
			<mat-icon color="primary" class="help" matTooltip="If enabled, this M-Message type will be accepted from upstream.">help_outline</mat-icon>
		</div>
		<div style="height:65px" class="row">
			<mat-form-field floatLabel="always" appearance="none">
        <mat-slide-toggle formControlName="ertAllowed" id="QA-Add-MMessage-Modal-ERT-Toggle">ERT Allowed</mat-slide-toggle>
        <textarea matInput hidden></textarea>
			</mat-form-field>
			<mat-icon color="primary" class="help" matTooltip="If enabled, this M-Message will be able to include ERT text. If disabled, it will never include ERT text">help_outline</mat-icon>
		</div>
		<div style="height:65px" class="row">
			<mat-form-field floatLabel="always" appearance="none">
        <mat-slide-toggle formControlName="canStartPsps" id="QA-Add-MMessage-Modal-StartPSPS-Toggle">Can Start PSPS</mat-slide-toggle>
        <textarea matInput hidden></textarea>
			</mat-form-field>
			<mat-icon color="primary" class="help" matTooltip="If enabled, this M-Message will be able to initiate a PSPS event.">help_outline</mat-icon>
		</div>
		<div class="row">
			<mat-form-field appearance="outline" class="half-length">
				<mat-label>Status</mat-label>
				<mat-select id="QA-Add-MMessageConfig-Modal-Status-Dropdown" formControlName="status" required>
          <mat-option *ngFor="let stat of mMessageStatusList" [value]="stat" id="QA-Add-MMessageConfig-Modal-Status-Dropdown-Option">{{ stat }}</mat-option>
				</mat-select>
				<mat-error *ngIf="mMessageForm.get('status').hasError('required')">This field is required.</mat-error>
			</mat-form-field>
			<mat-icon color="primary" class="help" matTooltip="Select the Status of this M-Message.">help_outline</mat-icon>
		</div>
		<div class="row">
			<mat-form-field appearance="outline" class="full-length">
				<mat-label>Tracker Text</mat-label>
				<input id="QA-Add-MMessage-Modal-TrackerText-Input" matInput formControlName="trackerText" maxlength="180">
        <mat-error *ngIf="mMessageForm.get('trackerText').hasError('maxlength')">This field must be less than 180 characters</mat-error>
			</mat-form-field>
			<mat-icon color="primary" class="help" matTooltip="Enter the Tracker Text to be associated with this M-Message.">help_outline</mat-icon>
		</div>
		<div style="padding-bottom: 10px;">
			<mat-dialog-actions align="end">
				<button id="QA-EditMMessage-Modal-CancelButton" mat-flat-button color="outline" class="add-mmessage-dialog-actions" (click)="closeDialog()">Cancel</button>
				<button id="QA-EditMMessage-Modal-SaveButton" [disabled]="determineDisableSaveButton()" mat-flat-button color="primary" class="add-mmessage-dialog-actions" (click)="verifyRequiredFields()">Save</button>
			</mat-dialog-actions>
		</div>
	</div>
</div>
