import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
// This service stores the token. It must be separate from the AuthService because of circular dependencies.
export class TokenService {

    private token = new BehaviorSubject<string>('token');
    public token$ = this.token.asObservable();

    constructor() { }

    setTokenValue(token: string) {
        this.token.next(token);
    }
}