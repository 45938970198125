import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AddPushNotificationRequest } from 'src/app/api/notification-api-client.service';
import { AffectedAddressService } from 'src/app/core/services/affected-address.service';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultLanguage } from 'src/app/shared/constants/language';
import { ConfirmationModalService } from 'src/app/shared/components/confirmation-modal/confirmation-modal.service';
import { ConfirmationModalType } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { EditPushNotificationContentComponent } from '../edit-push-notification-content-modal/edit-push-notification-content-modal.component';


@Component({
	selector: 'app-add-push-notification-content',
	templateUrl: './add-push-notification-content-modal.component.html',
	styleUrls: ['./add-push-notification-content-modal.component.css']
})

export class AddPushNotificationContentComponent implements OnInit {

  public mMessageConfigIds: string[] = [];
  public pushNotificationIds: string[] = [];

  pushNotificationContentForm: FormGroup;

  pushNotificationAdd: AddPushNotificationRequest = {
    id: "",
    language: DefaultLanguage,
    sendPushNotification: false,
    title: "",
    body: ""
  };

  notificationTypes: string[] = [];

  public readonly notificationTypeTooltip = "Choose the M-Message associated with this Push Notification.";
  public readonly titleTooltip = "Enter the title of this Push Notification. The title is limited to 40 characters.";
  public readonly bodyTooltip = "Enter the body of this push notification. The body is limited to 150 characters.";
  public readonly notificationsEnabledTooltip = "If enabled, when this M-Message is received notifications will be sent to active mobile app users.";

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private notificationService: NotificationService,
    private affectedAddressService: AffectedAddressService,
    public dialogRef: MatDialogRef<AddPushNotificationContentComponent>,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private confirmationModalService: ConfirmationModalService) { }

  async ngOnInit() {
    this.pushNotificationContentForm = this.formBuilder.group({
      id: this.data.pushNotificationContent?.id, // notification type
      title: this.data.pushNotificationContent?.title,
      notificationsEnabled: this.data.pushNotificationContent?.sendPushNotification ?? false,
      body: this.data.pushNotificationContent?.body
    });

    this.pushNotificationIds = this.data.ids;

    await this.getMMessageConfigs();

    this.refreshDropdownOptions();
  }

  closeDialog(): void {
    this.clearRouteParams();
    this.dialogRef.close();
  }

  refreshDropdownOptions(): void {
    // use Set to remove duplicates
    this.notificationTypes = [...new Set(this.mMessageConfigIds.filter(mMessageId =>
      !this.pushNotificationIds.includes(mMessageId)
    ))];
  }

  parseMId(id: string): number {
    try {
      return parseInt(id.match(/\d+/)[0]);
    } catch(err) {
      return 0;
    }
  }

  async getMMessageConfigs(): Promise<void> {
    try {
      const res = await firstValueFrom(this.affectedAddressService.getMMessageConfig());
      this.mMessageConfigIds = res.result.map(result => result.id).sort((a, b) => this.parseMId(a) - this.parseMId(b) || a.charCodeAt(a.length-1) - b.charCodeAt(b.length-1));
    } catch (error) {
      this.snackBarService.showErrorSnackBar(error.message);
      console.error(error);
      this.mMessageConfigIds = [];
    }
  }

  verifyRequiredFields(): void {
    this.pushNotificationContentForm.markAllAsTouched();
    if (this.pushNotificationContentForm.valid) {
      this.addPushNotificationContent();
    }
  }

  addPushNotificationContent() {
    this.pushNotificationAdd.id = this.pushNotificationContentForm.value.id;
    this.pushNotificationAdd.language = DefaultLanguage;
    this.pushNotificationAdd.sendPushNotification = this.pushNotificationContentForm.value.notificationsEnabled;
    this.pushNotificationAdd.title = this.pushNotificationContentForm.value.title;
    this.pushNotificationAdd.body = this.pushNotificationContentForm.value.body;

    this.notificationService.addPushNotificationContent(this.pushNotificationAdd).subscribe({
      next: async response => {
        this.snackBarService.showSuccessSnackBar();
        this.data.refresh();
        this.clearRouteParams();
        this.dialogRef.close();

        const afterConfirmed = await this.confirmationModalService.openConfirmationModal(ConfirmationModalType.addPushNotificationSuccess, null, null);

        if (afterConfirmed?.action === 'primary') {
          this.dialog.open(EditPushNotificationContentComponent, {
            height: 'auto',
            autoFocus: false,
            disableClose: false,
            data: {
              pushNotificationContentByLanguage: {
                [this.pushNotificationAdd.language]: {
                  sendPushNotification: this.pushNotificationAdd.sendPushNotification,
                  language: this.pushNotificationAdd.language,
                  id: this.pushNotificationAdd.id,
                  body: this.pushNotificationAdd.body,
                  title: this.pushNotificationAdd.title,
                },
              },
              refresh: this.data.refresh
            }
          });
        }
      }, error: err => {
        this.snackBarService.showErrorSnackBar(err.message);
        this.data.refresh();
        this.clearRouteParams();
        this.dialogRef.close();
      }
    });
  }

  private clearRouteParams(): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {}
      });
  }
}
