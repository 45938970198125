import { NgModule } from '@angular/core';
import { BadAddressModule } from '../bad-address/bad-address.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    BadAddressModule
  ]
})

export class DataManagementModule { }
