import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AffectedAddressApiClientService } from 'src/app/api/affected-address-api-client.service';

@Component({
  selector: 'app-bad-address-filters',
  templateUrl: './bad-address-filters.component.html',
  styleUrls: ['./bad-address-filters.component.css']
})
export class BadAddressFiltersComponent implements OnInit, OnDestroy {
  @Output() filtersChanged = new EventEmitter<any>();

  cities: string[] = [];
  postalCodes: string[] = [];

  filteredPostalCodes = new Observable<string[]>();
  filteredCities = new Observable<string[]>();

  public filters = new FormGroup({
    freetext: new FormControl(''),
    postalCode: new FormControl(),
    city: new FormControl(),
    isMapped: new FormControl()
  });

  public selections = new FormGroup({
    postalCode: new FormControl(),
    city: new FormControl(),
    // Start with isMapped filter set to 'Not Mapped'
    isMapped: new FormControl(false)
  });

  private readonly index = "nonmatched";

  private subscription: Subscription;
  private freetextSubscription: Subscription;

  constructor(
    private affectedAddressService: AffectedAddressApiClientService
  ) { }

  ngOnInit(): void {
    this.subscription = this.selections.valueChanges.subscribe(() => {
      this.emitUpdatedSearchCriteria();
    });

    this.freetextSubscription = this.filters.get('freetext').valueChanges.subscribe(() => {
      this.emitUpdatedSearchCriteria();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.freetextSubscription.unsubscribe();
  }

  emitUpdatedSearchCriteria() {
    this.filtersChanged.emit({
      address: this.filters.get('freetext').value,
      city: this.selections.get('city').value,
      postalCode: this.selections.get('postalCode').value,
      isMapped: this.selections.get('isMapped').value
    });
  }

  refreshDropdownOptions(isDropdownOpen: boolean, property: string) {
    if (isDropdownOpen) {
      this.affectedAddressService.getAddressAttribute(this.index, property).subscribe(
        response => {
          if (property === 'address.city') {
            this.cities = [];
            response.result.values.forEach(value => this.cities.push(value.key));
            this.filteredCities = this.filters.get('city').valueChanges.pipe(
              startWith(''),
              map(value => this.cities.filter(city => !value || city.toLowerCase().includes(value.toLowerCase())))
            );
          } else if (property === 'address.postalCode') {
            this.postalCodes = [];
            response.result.values.forEach(value => this.postalCodes.push(value.key));
            this.filteredPostalCodes = this.filters.get('postalCode').valueChanges.pipe(
              startWith(''),
              map(value => this.postalCodes.filter(code => !value || code.toLowerCase().includes(value.toLowerCase())))
            );
          }
        }
      );
    }
  }
}
