import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AffectedAddressService } from '../../core/services/affected-address.service';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';
import {
  SearchGoogleAutocompleteComponent
} from 'src/app/shared/components/search-google-autocomplete/search-google-autocomplete.component';

@Component({
  selector: 'app-add-blocked-address-modal',
  templateUrl: './add-blocked-address-modal.component.html',
  styleUrls: ['./add-blocked-address-modal.component.css']
})
export class AddBlockedAddressModalComponent implements OnInit {
  @ViewChild('blockedAddressMapContainer', { static: false }) gMapContainer: ElementRef;
  @ViewChild('autocomplete') autocompleteInput: SearchGoogleAutocompleteComponent;
  addAddressForm: FormGroup;
  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private place: google.maps.places.PlaceResult;

  blockPermanentlyOptions = [
    {
      name: 'Yes',
      value: true
    },
    {
      name: 'No',
      value: false
    }
  ];

  // is there an active PSPS event?
  public isPSPSEvent$ = this.affectedAddressService.isActiveEvent$;
  public activeEvent: boolean;

  // blocked addresses in dynamodb table
  public blockedAddresses$ = this.affectedAddressService.addresses$;

  public readonly blockedPermanentlyTooltip = "If toggle is enabled then this Address will be\
        suppressed from all future PSPS events. If disabled, then it will only be suppressed for\
        the currently active PSPS event.";

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private affectedAddressService: AffectedAddressService,
    public dialogRef: MatDialogRef<AddBlockedAddressModalComponent>) { }

    ngOnInit() {
      this.addAddressForm = this.formBuilder.group({
        address: null,
        blockedPermanently: null
      });

      this.dialogRef.afterOpened().subscribe(_ => {
        const coords = new google.maps.LatLng(32.715736, -117.161087);
        this.map = new google.maps.Map(this.gMapContainer.nativeElement, {
          center: coords,
          zoom: 10,
          disableDefaultUI: true,
          zoomControl: true
        });
      });
      
      this.isPSPSEvent$.subscribe( event => {
        this.activeEvent = event;
      });
    }

    closeDialog(): void {
      this.dialogRef.close();
    }

    onPlaceSelected(placeDetails: google.maps.places.PlaceResult) {
      this.place = placeDetails;
      const lat = placeDetails.geometry.location.lat();
      const lng = placeDetails.geometry.location.lng();
      this.addAddressForm.patchValue({address: this.formatAddress(placeDetails.formatted_address)})
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.marker = this.createMarker(lat, lng);
    }

    // helper function to help format google addresses to addressKey format
    formatAddress(address: string): string {
      let splitAddress = address.toLocaleLowerCase().replace(/,/g,'').split(' ');
      splitAddress.pop();
      return splitAddress.join('-');
    }

    // verify that the address doesn't already exist in the dynamodb table
    validateAddress(): boolean {
      let addressExists: boolean = false;

      this.blockedAddresses$.subscribe( addresses => {
        addresses.forEach( address => {
          if ( address.id === this.addAddressForm.get('address').value ) {
            addressExists = true;
          }
        })
      });

      if ( addressExists ) {
        this.snackBarService.showErrorSnackBar("This record already exists and won't be added to the table.");
        return false;
      }

      return true;
    }

    validateNewAddress(): boolean {
      // prompt to use google autocomplete
      if (this.addAddressForm.get('address').value === null) {
        this.snackBarService.showErrorSnackBar("Please enter a valid street address");
        return false;
      }
      // prompt to fill out blocked permanently field
      if (this.addAddressForm.get('blockedPermanently').value === null) {
        this.snackBarService.showErrorSnackBar("Please select an option for Blocked Permanently");
        return false;
      }

      // get active Event
      this.isPSPSEvent$.subscribe( event => { this.activeEvent = event; });
      
      // verify address doesn't already exist in table
      if ( !this.validateAddress() ) return false;

      this.addAddress();
      this.closeDialog();
      return true;
    }

    async addAddress() {
      let blockPermanently = this.addAddressForm.get('blockedPermanently').value;
      let addresses: string[] = [];
      addresses.push(this.addAddressForm.get('address').value);

      this.affectedAddressService.addBlockedAddress(addresses, blockPermanently).subscribe({
        next: () => {
          this.snackBarService.showSuccessSnackBar();
          this.dialogRef.close();
        }, error: err => {
          this.snackBarService.showErrorSnackBar(err.message);
          this.dialogRef.close();
        }
      });
    }

    /**
     * Applies the pin to the map at the location's address.
     */
    private createMarker(lat: number, lng: number): google.maps.Marker {
      const coords = new google.maps.LatLng(lat, lng);
      const marker = new google.maps.Marker({
        map: this.map,
        position: coords,
        icon: '/assets/icons/map-pin.svg'
      });
      marker.setMap(this.map);
      if (marker.getPosition()) {
        this.map.setCenter(coords);
      }
      return marker;
    }
}