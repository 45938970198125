import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActiveAddress, AddressHistory, MMessageConfig, PatchAffectedAddressItem } from 'src/app/api/affected-address-api-client.service';
import { GeocodeService } from 'src/app/core/services/geocode.service';
import { AffectedAddressService } from 'src/app/core/services/affected-address.service';
import { AddressHistoryAudit } from 'src/app/models/address-history-audit.model';
import { MatSelectChange } from '@angular/material/select';
import { StatusNamePipe } from 'src/app/shared/pipes/status-name.pipe';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { SnackBarService } from 'src/app/shared/components/snackbar/snackbar.service';
import { TableUpdateService } from '../table-update/table-update.service';
import { AddressModalInput } from '../../../shared/components/confirmation-modal/confirmation-modal.service';
import { FullAddressPipe } from 'src/app/shared/pipes/full-address.pipe';
import { Languages } from 'src/app/shared/constants/language';
@Component({
  selector: 'app-address-details-modal',
  templateUrl: './address-details-modal.component.html',
  styleUrls: ['./address-details-modal.component.css']
})
export class AddressDetailsModalComponent implements OnInit, AfterViewInit {
  @ViewChild('addressDetailsMapContainer', { static: false }) gMapContainer: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  addressDetailsForm: FormGroup;
  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private lat: number;
  private lng: number;
  private isMMessageSelected: boolean = false;

  public displayedHistoryColumns = [
    'Summary',
    'Editor',
    'Time of Change',
    'Source'
  ];

  public messageLevels: MMessageConfig[] = [];

  public addressHistory = new MatTableDataSource<AddressHistory>();

  constructor(
    private affectedAddressService: AffectedAddressService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddressDetailsModalComponent>,
    public geocodeService: GeocodeService,
    private datePipe: DatePipe,
    private statusNamePipe: StatusNamePipe,
    private fullAddressPipe: FullAddressPipe,
    private snackBarService: SnackBarService,
    private tableUpdateService: TableUpdateService,
    @Inject(MAT_DIALOG_DATA) public data: AddressModalInput
  ) { }

  async ngOnInit() {
    this.getAddressHistory();

    this.addressDetailsForm = this.formBuilder.group({
      fullAddress: this.fullAddressPipe.transform(this.data.address),
      sectionalizationDeviceId: this.data.address.sectionalizationDeviceId,
      statusSource: null,
      estimatedRestorationTime: this.formatErtStringForDatepicker(this.data.address.estimatedRestorationTime)
    });

    this.messageLevels = this.data.mMessageLevels.filter(level => level.language === Languages.English);
    const currentStatusSource = this.messageLevels.find(
      message => message.id === this.data.address.statusSource
    );
    if (currentStatusSource) {
      this.addressDetailsForm.get('statusSource').setValue(currentStatusSource);
      this.updateEditableErt(currentStatusSource.ertAllowed);
      this.isMMessageSelected = true;
    }

    this.addressDetailsForm.get('fullAddress').disable();
    this.addressDetailsForm.get('sectionalizationDeviceId').disable();
  }

  async ngAfterViewInit() {
    this.sort.sort(({ id: 'createdOn', start: 'desc', disableClear: true }));
    this.addressHistory.sort = this.sort;

    const geolocation = await this.geocodeService.getGeoLocation(this.fullAddressPipe.transform(this.data.address));
    this.lat = geolocation.lat;
    this.lng = geolocation.lng;

    const coords = new google.maps.LatLng(this.lat, this.lng);
    this.map = new google.maps.Map(this.gMapContainer.nativeElement, {
      center: coords,
      zoom: 10,
      disableDefaultUI: true,
      zoomControl: true
    });

    this.setMapMarker(this.lat, this.lng);
  }

  getAddressHistory() {
    this.affectedAddressService.getAddressHistory(this.data.address.id).subscribe({
      next: history => this.addressHistory.data = this.getAddressHistoryWithAudit(history.result.addressHistory)
    });
  }

  getAddressHistoryWithAudit(addressList: AddressHistory[]): AddressHistoryAudit[] {
    const addressHistoryList: AddressHistoryAudit[] = addressList;
    if (addressHistoryList.length > 1) {
      for (let i = 1; i < addressHistoryList.length; i++) {
        addressHistoryList[i].audit = this.getAudit(addressHistoryList[i - 1], addressHistoryList[i]);
      }
    }
    return addressHistoryList;
  }

  getAudit(previousEntry: AddressHistory, nextEntry: AddressHistory): string {
    let audit: string;
    if (previousEntry.statusSource !== nextEntry.statusSource) {
      if (nextEntry.statusSource === 'outage') {
        audit = 'Changed status to ' + this.statusNamePipe.transform(nextEntry.status);
      } else {
        audit = 'Changed status to ' + nextEntry.statusSource;
      }
    } else if (previousEntry.estimatedRestorationTime !== nextEntry.estimatedRestorationTime) {
      audit = 'ERT updated';
    } else if (previousEntry.estimatedRestorationTimeSrc === 'portal' && nextEntry.estimatedRestorationTimeSrc === 'outage') {
      audit = 'ERT override removed';
    } else {
      if (nextEntry.statusSource === 'outage') {
        audit = 'Changed status to ' + this.statusNamePipe.transform(nextEntry.status);
      } else {
        audit = 'Changed status to ' + nextEntry.statusSource;
      }
    }
    return audit;
  }

  setMapMarker(latitude: number, longitude: number) {
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = this.createMarker(latitude, longitude);
  }

  formatErtStringForDatepicker(estimatedRestorationTime: string): string {
    return this.datePipe.transform(estimatedRestorationTime, 'yyyy-MM-ddTHH:mm');
  }

  formatErtStringForPatchRequest(): string {
    const estimatedRestorationTime = this.addressDetailsForm.value.estimatedRestorationTime;
    return new Date(estimatedRestorationTime).toISOString();
  }

  saveAddressDetails() {
    const addressChanges: PatchAffectedAddressItem[] = [];
    const updatedAddress: ActiveAddress = this.data.address;
    let newStatusSource: string;

    // Determine any changed values
    if (this.isMMessageSelected &&
      (this.data.address.statusSource !== this.addressDetailsForm.value.statusSource.id)) {
      newStatusSource = this.addressDetailsForm.value.statusSource.id;
      addressChanges.push({
        property: 'statusSource',
        value: newStatusSource,
        id: updatedAddress.id
      });

      updatedAddress.statusSource = newStatusSource;
    }

    // Ensure ERT field is defined and value has been changed
    if (this.addressDetailsForm.value.estimatedRestorationTime &&
      this.addressDetailsForm.value.estimatedRestorationTime !==
      this.formatErtStringForDatepicker(this.data.address.estimatedRestorationTime)
    ) {

      const newErt = this.formatErtStringForPatchRequest();

      addressChanges.push({
        property: 'estimatedRestorationTime',
        value: newErt,
        id: updatedAddress.id
      });

      updatedAddress.estimatedRestorationTime = newErt;
      updatedAddress.estimatedRestorationTimeSrc = 'portal';
    }

    if (addressChanges.length > 0) {
      this.updateAddress(this.data.address, updatedAddress, addressChanges);
    } else {
      this.dialogRef.close();
    }
  }

  private updateAddress(
    originalAddress: ActiveAddress,
    updatedAddress: ActiveAddress,
    patchUpdates: PatchAffectedAddressItem[]): void {
    this.affectedAddressService.patchAffectedAddresses(patchUpdates).subscribe({
      next: response => {
        if (response.result.itemsWithErrors === 0) {
          this.snackBarService.showSuccessSnackBar();
          this.tableUpdateService.updateAddress(this.data.addresses, originalAddress, updatedAddress);
          this.dialogRef.close();
        } else {
          this.snackBarService.showErrorSnackBar();
        }
      },
      error: err => {
        console.error('Error occured: ' + err);
        this.snackBarService.showErrorSnackBar();
      }
    });
  }

  onMessageLevelSelected(messageSelection: MatSelectChange): void {
    // Check if ERT field is valid for this message level and disable if not
    this.updateEditableErt(messageSelection.value.ertAllowed ?? false);
    this.isMMessageSelected = true;
  }

  updateEditableErt(isErtEnabled: boolean) {
    if (isErtEnabled) {
      this.addressDetailsForm.get('estimatedRestorationTime').enable();
    } else {
      this.addressDetailsForm.get('estimatedRestorationTime').setValue(null);
      this.addressDetailsForm.get('estimatedRestorationTime').disable();
    }
  }

  /**
   * Applies the pin to the map at the location's address.
   */
  private createMarker(lat: number, lng: number): google.maps.Marker {
    const coords = new google.maps.LatLng(lat, lng);
    const marker = new google.maps.Marker({
      map: this.map,
      position: coords,
      icon: '/assets/icons/map-pin.svg'
    });
    marker.setMap(this.map);
    if (marker.getPosition()) {
      this.map.setCenter(coords);
    }
    return marker;
  }
}
