import { Injectable } from '@angular/core';
import { Bounds } from '../../api/affected-address-api-client.service';

declare global {
  interface Window {
    google: {
      maps: any;
    };
  }
}

@Injectable()
export class MapService {
  private readonly scriptId: string = 'sdgeGoogleMapsApi';
  private readonly callbackName: string = 'sdgeGoogleMapsLoaded';
  private scriptLoaded: Promise<void> | undefined;
  public loaded = false;
  public apiKey = '';
  public serviceArea: Bounds;

  constructor() {}

  async load(mapsApiKey: string): Promise<void> {
    this.apiKey = mapsApiKey;
    if (window.google && window.google.maps) {
      this.loaded = true;
      return Promise.resolve();
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.id = this.scriptId;
    script.src = this.getScriptSrc(mapsApiKey, this.callbackName);

    this.createScriptLoadingPromise(script);

    document.body.appendChild(script);

    this.loaded = true;
    return this.scriptLoaded;
  }

  private getScriptSrc(apiKey: string, callbackName: string): string {
    return `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization,drawing&callback=${callbackName}`;
  }

  private createScriptLoadingPromise(scriptEl: HTMLElement) {
    this.scriptLoaded = new Promise<void>((resolve, reject) => {
      const win = window as { [key: string]: any };
      win[this.callbackName] = (): void => {
        delete win[this.callbackName];

        resolve();
      };

      scriptEl.onerror = (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error
      ) => {
        reject(error);
      };
    });
  }
}
