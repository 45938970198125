<div id="QA-MmessageSettingsPageContainer" class="container">
    <ng-container>
      <div class="manage-header">
        <h6 id="QA-Mmessage-Title">
          M-Message Settings
        </h6>
        <ng-container matColumnDef="AddMMessage">
          <span>
            <button id="QA-MMessage-AddMMessageButton" mat-button color="primary" class="add-button"
            (click)="openAddModal()">+ Add M-Message</button>
          </span>
        </ng-container>
      </div>
      <div fxLayout="row">
        <table mat-table id="mMessageConfigs" [dataSource]="mMessageConfigsList">
          <ng-container matColumnDef="Id">
            <th style="width: 6%;" id="QA-Mmessage-Row-IdHeader" mat-header-cell *matHeaderCellDef>
              ID
              <mat-icon color="primary" class="help" matTooltip="{{ tooltips.id }}" matTooltipPosition="above">help_outline</mat-icon>
            </th>
            <td id="QA-Mmessage-Row-Id" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.id || '-' }}</td>
          </ng-container>

          <ng-container matColumnDef="IngestionEnabled">
            <th id="QA-Mmessage-Row-IngestionEnabledHeader" mat-header-cell *matHeaderCellDef>
              Ingestion Enabled
              <mat-icon color="primary" class="help" matTooltip="{{ tooltips.ingestionEnabled }}" matTooltipPosition="above">help_outline</mat-icon>
            </th>
            <td id="QA-Mmessage-Row-IngestionEnabled" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.ingestionEnabled ?? false }}</td>
          </ng-container>

          <ng-container matColumnDef="CanStartPSPS">
            <th id="QA-Mmessage-Row-CanStartPSPSHeader" mat-header-cell *matHeaderCellDef>
              Can Start PSPS
              <mat-icon color="primary" class="help" matTooltip="{{ tooltips.canStartPsps }}" matTooltipPosition="above">help_outline</mat-icon>
            </th>
            <td id="QA-Mmessage-Row-CanStartPSPS" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.canStartPsps ?? false }}</td>
          </ng-container>

          <ng-container matColumnDef="ERTAllowed">
            <th id="QA-Mmessage-Row-ERTAllowedHeader" mat-header-cell *matHeaderCellDef>
              ERT Allowed
              <mat-icon color="primary" class="help" matTooltip="{{ tooltips.ertAllowed }}" matTooltipPosition="above">help_outline</mat-icon>
            </th>
            <td id="QA-Mmessage-Row-ERTAllowed" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.ertAllowed ?? false }}</td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th id="QA-Mmessage-Row-StatusHeader" mat-header-cell *matHeaderCellDef>
              Status
              <mat-icon color="primary" class="help" matTooltip="{{ tooltips.status }}" matTooltipPosition="above">help_outline</mat-icon>
            </th>
            <td id="QA-Mmessage-Row-Status" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.status || '-' }}</td>
          </ng-container>

          <ng-container matColumnDef="TrackerText">
            <th style="width: 24%;" id="QA-Mmessage-Row-TrackerTextHeader" mat-header-cell *matHeaderCellDef>
              Tracker Text
              <mat-icon color="primary" class="help" matTooltip="{{ tooltips.trackerText }}" matTooltipPosition="above">help_outline</mat-icon>
            </th>
            <td style="text-overflow: ellipsis;" id="QA-Mmessage-Row-TrackerText" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.trackerText || '-' }}</td>
          </ng-container>

          <ng-container matColumnDef="Languages">
            <th mat-header-cell *matHeaderCellDef id="QA-Mmessage-Row-LanguagesHeader">Languages</th>
            <td mat-cell *matCellDef="let element" id="QA-Mmessage-Row-LanguagesText">
              {{ displayLanguagesForRow(element) }}
              <mat-icon *ngIf="displayMissingLanguageTooltip(element)" color="primary" class="help" matTooltip="{{ tooltips.missingLanguageTooltipMessage }}" matTooltipPosition="above">help_outline</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="LastUpdated">
            <th id="QA-Mmessage-Row-LastUpdatedHeader" mat-header-cell *matHeaderCellDef>Last Updated</th>
            <td id="QA-Mmessage-Row-LastUpdated" mat-cell *matCellDef="let element">{{ element[mainLanguage]?.updatedOn * 1000 | date: "M/d/yyyy \'at\' h:mm a" }}</td>
          </ng-container>

          <ng-container matColumnDef="Edit">
            <th style="width: 5%;" mat-header-cell *matHeaderCellDef></th>
            <td  mat-cell *matCellDef="let element">
              <button mat-flat-button color="white" (click)="openEditModal(element)" id="QA-Mmessage-Row-Edit">Edit</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="Delete">
            <th style="width: 10%;" mat-header-cell *matHeaderCellDef></th>
            <td id="QA-Mmessage-Row-Delete" mat-cell *matCellDef="let element">
              <button mat-flat-button color="white" (click)="deleteMMessage(element)">Delete</button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </ng-container>

    <div *ngIf="isLoading" class="spinner-container">
      <div class="spinner-text">
        This search may take a minute...
        <div>
          <mat-progress-spinner diameter="23" color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>
