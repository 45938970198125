import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  private idleLogout: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private idleService: UserIdleService, private appConfigService: AppConfigService) {
    const activeUserEvents = merge(
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click')
    );

    this.idleService.setCustomActivityEvents(activeUserEvents);
    this.idleService.setConfigValues({ idle: this.appConfigService.config.idleSessionTimeout });
  }

  setIdleLogoutState(isIdleLogout: boolean) {
    this.idleLogout.next(isIdleLogout);
  }

  getIdleLogoutState(): Observable<boolean> {
    return this.idleLogout.asObservable();
  }

  startWatching() {
    this.idleService.startWatching();
  }

  stopWatching() {
    this.idleService.stopWatching();
  }

  onTimerStart(): Observable<number> {
    return this.idleService.onTimerStart();
  }
}
