<div id="QA-BadAddressPageContainer" class="container">
  <ng-container>
    <div class="manage-header">
      <h6 id="QA-BadAddress-Title">
        Invalid Address Management
      </h6>

      <button class="right-align" mat-flat-button color="primary" *ngIf="selection.selected.length > 0"
        (click)="openReplaceModal({replaceMultiple: true})">
        Replace Multiple Addresses
      </button>
    </div>
    <app-bad-address-filters (filtersChanged)="onSearchCriteriaChanged($event)"></app-bad-address-filters>
    <table mat-table id="addressesList" [dataSource]="addressesList">

      <ng-container matColumnDef="Checkbox">
        <th id="QA-BadAddress-Row-CheckboxHeader" mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td id="QA-BadAddress-Row-Checkbox" mat-cell *matCellDef="let element">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="Address">
        <th id="QA-BadAddress-Row-AddressHeader" mat-header-cell *matHeaderCellDef> Address </th>
        <td id="QA-BadAddress-Row-Address" mat-cell *matCellDef="let element"> {{ element.address | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="City">
        <th id="QA-BadAddress-Row-CityHeader" mat-header-cell *matHeaderCellDef> City </th>
        <td id="QA-BadAddress-Row-City" mat-cell *matCellDef="let element"> {{ element.city | titlecase }} </td>
      </ng-container>

      <ng-container matColumnDef="State">
        <th id="QA-BadAddress-Row-StateHeader" mat-header-cell *matHeaderCellDef> State </th>
        <td id="QA-BadAddress-Row-State" mat-cell *matCellDef="let element"> {{ element.state }} </td>
      </ng-container>

      <ng-container matColumnDef="Zip">
        <th id="QA-BadAddress-Row-ZipHeader" mat-header-cell *matHeaderCellDef> Zip </th>
        <td id="QA-BadAddress-Row-Zip" mat-cell *matCellDef="let element"> {{ element.postalCode }} </td>
      </ng-container>

      <ng-container matColumnDef="TimeReceived">
        <th id="QA-BadAddress-Row-TimeHeader" mat-header-cell *matHeaderCellDef> Last Received </th>
        <td id="QA-BadAddress-Row-Time" mat-cell *matCellDef="let element"> {{ element.updatedOn | date:
          "M/d/yyyy \'at\' h:mm a" }} </td>
      </ng-container>

      <ng-container matColumnDef="MappedTo">
        <th id="QA-BadAddress-Row-MappedToHeader" mat-header-cell *matHeaderCellDef> Mapped To </th>
        <td id="QA-BadAddress-Row-MappedTo" mat-cell *matCellDef="let element"> {{ element.mappedAddress || '-' }} </td>
      </ng-container>

      <ng-container matColumnDef="Replace">
        <th mat-header-cell *matHeaderCellDef></th>
        <td id="QA-BadAddress-Row-Replace" mat-cell *matCellDef="let element">
          <button mat-flat-button color="white" class="small" (click)="openReplaceModal(element)">Replace</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="row.checked = !row.checked"></tr>
    </table>
    <mat-paginator *ngIf="addressesList.data?.length > 0" [pageSize]="pagination.value.pageSize"
      [pageIndex]="pagination.value.pageIndex" [length]="totalRows" (page)="onPageChanged($event)"
      [pageSizeOptions]="[25, 50, 100]">
    </mat-paginator>
  </ng-container>

  <div *ngIf="isLoading" class="spinner-container">
    <div class="spinner-text">
      This search may take a minute...
      <div>
        <mat-progress-spinner diameter="23" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
  </div>
</div>