import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  GetActiveAddressesRequestSortOrder,
  NonMatchedAddressItem,
  NonMatchedAddressSortKey,
  ServiceAddress
} from '../api/affected-address-api-client.service';
import { ReplaceAddressModalComponent } from './replace-address-modal/replace-address-modal.component';
import { AffectedAddressService } from '../core/services/affected-address.service';
import { SnackBarService } from '../shared/components/snackbar/snackbar.service';
import { ConfirmationModalService } from '../shared/components/confirmation-modal/confirmation-modal.service';
import { ConfirmationModalType } from '../shared/components/confirmation-modal/confirmation-modal.component';
interface MultipleAddressFlag {
  replaceMultiple: boolean;
}

@Component({
  selector: 'app-bad-address',
  templateUrl: './bad-address.component.html',
  styleUrls: ['./bad-address.component.css']
})
export class BadAddressesComponent implements OnInit, OnDestroy {
  public addressesList = new MatTableDataSource<NonMatchedAddressItem>();
  public selection = new SelectionModel<any>(true, []);
  public replaceMultiple = false;

  public displayedColumns = [
    'Checkbox',
    'Address',
    'City',
    'State',
    'Zip',
    'TimeReceived',
    'MappedTo',
    'Replace'
  ];
  public isLoading = false;

  private googleApiResponse: any;
  private googlePlaceId: string;
  private replaceId: string;

  public pagination = new FormGroup({
    pageSize: new FormControl(50),
    pageIndex: new FormControl(0),
    previousPageIndex: new FormControl(0)
  });

  public totalRows: number = 0;

  // ES sort key of data on current page.
  private sortKey?: NonMatchedAddressSortKey;
  private addressQueryRequest: Subject<any> = new Subject();
  private filtersSubscription: Subscription;

  private addressFilter: string | undefined;
  private cityFilter: string[] | undefined;
  private postalCodeFilter: string[] | undefined;
  private isMappedFilter: boolean | undefined;

  constructor(
    private affectedAddressService: AffectedAddressService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit() {
    this.filtersSubscription = this.addressQueryRequest.pipe(
      tap(_ => {
        this.addressesList.data = null;
        this.selection.clear();
      }),
      switchMap(params => this.affectedAddressService.getNonmatchedAddresses(
        params.address,
        params.searchAfter,
        params.city,
        params.sortOrder,
        params.postalCode,
        params.pageSize,
        params.isMapped
      ).pipe(
        catchError(e => {
          console.error(e);
          this.snackBarService.showErrorSnackBar(e.message);
          return EMPTY;
        }),
        map(res => res.result),
        map(res => params.sortOrder === GetActiveAddressesRequestSortOrder.Asc
          // Results must be reversed when paginating backwards to maintain order.
          ? { ...res, addresses: res.addresses.reverse(), sortKey: { first: res.sortKey.last, last: res.sortKey.first } }
          : res
        )
      ))
    )
      .subscribe(res => {
        this.addressesList.data = res.addresses;
        this.totalRows = res.total;
        this.sortKey = res.sortKey;
      });

    this.onSearchCriteriaChanged({ isMapped: false });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  // Whether the number of selected elements matches the total number of rows.
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.addressesList.filteredData.length;
    return numSelected === numRows;
  }

  // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.addressesList.filteredData.forEach(row => this.selection.select(row));
  }

  openReplaceModal(address: ServiceAddress | MultipleAddressFlag) {
    this.replaceMultiple = (address as MultipleAddressFlag).replaceMultiple;

    this.dialog.open(ReplaceAddressModalComponent, {
      width: '700px',
      height: '550px',
      autoFocus: false,
      disableClose: false,
      data: address
    }).afterClosed().subscribe(modalResult => {
      if (modalResult && typeof modalResult === 'object') {
        this.googleApiResponse = modalResult.googleApiResponse;
        this.googlePlaceId = modalResult.googlePlaceId;
        this.replaceId = modalResult.id;

        this.confirmationModalService.openConfirmationModal(
          ConfirmationModalType.replace,
          this.replaceMultiple ? this.selection.selected.length : 1,
          this.googleApiResponse.formatted_address
        ).then(afterConfirmed => {
          if (afterConfirmed.action === 'primary') {
            this.replaceAddress();
          } else {
            this.resetReplaceAddressData();
          }
        })
      }
    });
  }

  replaceAddress() {
    let selectedAddressKeys = [];
    if (!this.replaceMultiple) {
      selectedAddressKeys.push(this.replaceId);
    } else {
      selectedAddressKeys = this.selection.selected.map(x => x.id);
    }
    const replaceRequest = {
      address: this.googleApiResponse,
      googlePlaceId: this.googlePlaceId,
      nonMatchedAddressKeys: selectedAddressKeys
    };

    this.affectedAddressService.mapAddress(replaceRequest).pipe(
      catchError(error => {
        this.snackBarService.showErrorSnackBar(error.data);
        return of(null);
      })
    ).subscribe(response => {
      if (response != null) {
        const ids = response.result.map(x => x.id);
        this.addressesList.data = this.addressesList.data.filter(tableItem => !(ids.includes(tableItem.id)));
        this.snackBarService.showSuccessSnackBar();
      }
      this.resetReplaceAddressData();
    });
  }

  resetReplaceAddressData() {
    this.googleApiResponse = null;
    this.googlePlaceId = null;
    this.replaceId = null;
    this.replaceMultiple = false;
  }

  onPageChanged(event: PageEvent) {
    const pageSizeChanged = event.pageSize !== this.pagination.value.pageSize;
    if (pageSizeChanged) {
      this.sortKey = null;
    }
    this.pagination.setValue({
      pageIndex: pageSizeChanged ? 0 : event.pageIndex,
      pageSize: event.pageSize,
      previousPageIndex: pageSizeChanged ? 0 : event.previousPageIndex
    });
    this.addressQueryRequest.next({
      sortOrder: (this.pagination.value.pageIndex >= this.pagination.value.previousPageIndex)
        ? GetActiveAddressesRequestSortOrder.Desc
        : GetActiveAddressesRequestSortOrder.Asc,
      address: this.addressFilter,
      city: this.cityFilter,
      postalCode: this.postalCodeFilter,
      pageSize: this.pagination.value.pageSize,
      searchAfter: (this.pagination.value.pageIndex >= this.pagination.value.previousPageIndex) ?
        this.sortKey?.last : this.sortKey?.first,
      isMapped: this.isMappedFilter
    });
  }

  onSearchCriteriaChanged(searchCriteria: any) {
    this.addressFilter = searchCriteria.address;
    this.cityFilter = searchCriteria.city;
    this.postalCodeFilter = searchCriteria.postalCode;
    this.isMappedFilter = searchCriteria.isMapped;
    // reset back to the first page when search criteria changes
    const pageSize = this.pagination.get('pageSize').value;
    this.sortKey = null;
    this.pagination.setValue({
      pageIndex: 0,
      pageSize,
      previousPageIndex: 0
    });
    this.addressQueryRequest.next({
      sortOrder: GetActiveAddressesRequestSortOrder.Desc,
      address: searchCriteria.address,
      city: searchCriteria.city,
      postalCode: searchCriteria.postalCode,
      pageSize,
      isMapped: searchCriteria.isMapped
    });
  }
}
