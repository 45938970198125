import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlockedAddressItem } from '../../api/affected-address-api-client.service';
import { AffectedAddressService } from '../../core/services/affected-address.service';
import { SnackBarService } from '../../shared/components/snackbar/snackbar.service';

@Component({
  selector: 'app-add-blocked-postal-code-modal',
  templateUrl: './add-blocked-postal-code-modal.component.html',
  styleUrls: ['./add-blocked-postal-code-modal.component.css']
})
export class AddBlockedPostalCodeModalComponent implements OnInit {

  addPostalCodeForm: FormGroup;

  blockPermanentlyOptions = [
    {
      name: 'Yes',
      value: true
    },
    {
      name: 'No',
      value: false
    }
  ];

  // is there an active PSPS event?
  public isPSPSEvent$ = this.affectedAddressService.isActiveEvent$;
  public activeEvent: boolean;

  // blocked addresses in dynamodb table
  public blockedAddresses$ = this.affectedAddressService.addresses$;

  public readonly blockedPermanentlyTooltip = "If toggle is enabled then this Postal Code will be\
        suppressed from all future PSPS events. If disabled, then it will only be suppressed for\
        the currently active PSPS event.";

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private affectedAddressService: AffectedAddressService,
    public dialogRef: MatDialogRef<AddBlockedPostalCodeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public ingestionItem: BlockedAddressItem) { }

    ngOnInit() {
      this.addPostalCodeForm = this.formBuilder.group({
        postalCode: null,
        blockedPermanently: null
      });
      
      this.isPSPSEvent$.subscribe( event => {
        this.activeEvent = event;
      });
    }

    closeDialog(): void {
      this.dialogRef.close();
    }

    // verify that the postal code doesn't already exist in the dynamodb table
    validatePostalCode(): boolean {
      let postalCodeExists: boolean = false;

      // is postal code a 5 digit number?
      if ( this.addPostalCodeForm.get('postalCode').value.length !== 5 )
        return false;

      this.blockedAddresses$.subscribe( addresses => {
        addresses.forEach( address => {
          if ( address.id === this.addPostalCodeForm.get('postalCode').value ) {
            postalCodeExists = true;
          }
        })
      });

      if ( postalCodeExists ) {
        this.snackBarService.showErrorSnackBar("This record already exists and won't be added to the table.");
        return false;
      }

      return true;
    }

    validateNewPostalCode(): boolean {

      // one or more required field(s) empty
      if ( this.addPostalCodeForm.get('blockedPermanently').value === null ||
            !this.addPostalCodeForm.get('postalCode').value ) {
        return false;
      }

      // verify active PSPS
      // if there is not, user can only block zip code permanently
      this.isPSPSEvent$.subscribe( event => {
        this.activeEvent = event;
        if ( this.activeEvent === false && this.addPostalCodeForm.get('blockedPermanently').value === false ) {
          return false;
        }
      });
      
      // verify postal code doesn't already exist in table
      if ( !this.validatePostalCode() ) {
        return false;
      }

      // add if all checks pass, add zip code to table
      this.addPostalCode();

      this.closeDialog();
      return true;
    }

    async addPostalCode() {
      let postalCodes: string[] = [];
      postalCodes.push(this.addPostalCodeForm.get('postalCode').value);

      this.affectedAddressService.blockPostalCodes(postalCodes, this.addPostalCodeForm.get('blockedPermanently').value).subscribe({
        next: response => {
          this.snackBarService.showSuccessSnackBar();
          this.dialogRef.close();
        }, error: err => {
          this.snackBarService.showErrorSnackBar(err.message);
          this.dialogRef.close();
        }
      });
    }
}