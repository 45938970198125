<div id="QA-DataOverrideMatrixPageContainer" class="container">
    <ng-container>
        <div class="manage-header">
            <h6 id="QA-DataOverrideMatrix-Title">
                Data Override Rules Matrix
            </h6>
        </div>

        <div class="col" class="rules-label">
            <div id="QA-DataOverrideMatrix-Rules">Override Rules: </div>
            <ul>
                <li>PSPS Outage will always override Unplanned or Assessing; PSPSOutage overrides everything</li>
                <li>Patrolling with always override Unplanned or Assessing</li>
                <li>Power restored will always override Unplanned or Assessing</li>
                <li>UnplannedOutage is completely ignored</li>
            </ul>
        </div>

        <table mat-table [dataSource]="dataSource" class="table-style">
            <!-- Current State/Override Column -->
            <ng-container matColumnDef="currentState">
                <th mat-header-cell #col1 *matHeaderCellDef class="background"> 
                    <div class="top-cell">  
                        <div class="top"></div>
                        <div class="override">Override</div>
                        <div class="current-state">Current <br> State</div>
                    </div>
                </th>
                <td mat-cell #col1 class="ignore-hover" (mouseenter)="addColumnColor('col1')" (mouseleave)="removeColumnColor('col1')" *matCellDef="let element"> {{element.currentState}} </td>
            </ng-container>
            <!-- Source Potential Outage Column -->
            <ng-container matColumnDef="PotentialOutage">
                <th mat-header-cell #col2 class="ignore" *matHeaderCellDef> Potential Outage </th>
                <td mat-cell #col2 (mouseenter)="addColumnColor('col2')" (mouseleave)="removeColumnColor('col2')" matTooltip="{{element.PotentialOutageToolTip}}" *matCellDef="let element"> {{element.PotentialOutage}} </td>
            </ng-container>
            <!-- Source PSPS Outage Column -->
            <ng-container matColumnDef="PSPSOutage">
                <th mat-header-cell #col3 *matHeaderCellDef> PSPS Outage </th>
                <td mat-cell #col3 (mouseenter)="addColumnColor('col3')" (mouseleave)="removeColumnColor('col3')" matTooltip="{{element.PSPSOutageToolTip}}" *matCellDef="let element"> {{element.PSPSOutage}} </td>
            </ng-container>
            <!-- Source On Patrol Column -->
            <ng-container matColumnDef="OnPatrol">
                <th mat-header-cell #col4 *matHeaderCellDef> On Patrol </th>
                <td mat-cell #col4 (mouseenter)="addColumnColor('col4')" (mouseleave)="removeColumnColor('col4')" matTooltip="{{element.OnPatrolToolTip}}" *matCellDef="let element"> {{element.OnPatrol}} </td>
            </ng-container>
            <!-- Source Power Restored Column -->
            <ng-container matColumnDef="PowerRestored">
                <th mat-header-cell #col5 *matHeaderCellDef> Power Restored </th>
                <td mat-cell #col5 (mouseenter)="addColumnColor('col5')" (mouseleave)="removeColumnColor('col5')" matTooltip="{{element.PowerRestoredToolTip}}" *matCellDef="let element"> {{element.PowerRestored}} </td>
            </ng-container>
            <!-- Source No PSPS Column -->
            <ng-container matColumnDef="NoPSPS">
                <th mat-header-cell #col6 *matHeaderCellDef> No Event </th>
                <td mat-cell #col6 (mouseenter)="addColumnColor('col6')" (mouseleave)="removeColumnColor('col6')" matTooltip="{{element.NoPSPSToolTip}}" *matCellDef="let element"> {{element.NoPSPS}} </td>
            </ng-container>
            <!-- Source Unplanned Column -->
            <ng-container matColumnDef="unplanned">
                <th mat-header-cell #col7 *matHeaderCellDef> Outage.json: Unplanned cause </th>
                <td mat-cell #col7 (mouseenter)="addColumnColor('col7')" (mouseleave)="removeColumnColor('col7')" matTooltip="{{element.unplannedToolTip}}" *matCellDef="let element"> {{element.unplanned}} </td>
            </ng-container>
            <!-- Source Assessing Column -->
            <ng-container matColumnDef="assessing">
                <th mat-header-cell #col8 *matHeaderCellDef> Outage.json: Assessing cause </th>
                <td mat-cell #col8 (mouseenter)="addColumnColor('col8')" (mouseleave)="removeColumnColor('col8')" matTooltip="{{element.assessingToolTip}}" *matCellDef="let element"> {{element.assessing}} </td>
            </ng-container>
            <!-- Source Shut Off Column -->
            <ng-container matColumnDef="shutoff">
                <th mat-header-cell #col9 *matHeaderCellDef> Outage.json: PSPS Shut Off </th>
                <td mat-cell #col9 (mouseenter)="addColumnColor('col9')" (mouseleave)="removeColumnColor('col9')" matTooltip="{{element.shutoffToolTip}}" *matCellDef="let element"> {{element.shutoff}} </td>
            </ng-container>
            <!-- Source Restored Column -->
            <ng-container matColumnDef="restored">
                <th mat-header-cell #col10 *matHeaderCellDef> Outage.json: Restored </th>
                <td mat-cell #col10 (mouseenter)="addColumnColor('col10')" (mouseleave)="removeColumnColor('col10')" matTooltip="{{element.restoredToolTip}}" *matCellDef="let element"> {{element.restored}} </td>
            </ng-container>
            <!-- Source End Column -->
            <ng-container matColumnDef="end">
                <th mat-header-cell #col11 *matHeaderCellDef> Management Portal: PSPS END </th>
                <td mat-cell #col11 (mouseenter)="addColumnColor('col11')" (mouseleave)="removeColumnColor('col11')" matTooltip="{{element.endToolTip}}" *matCellDef="let element"> {{element.end}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" #matrow class="row-hover" ></tr>
        </table>

        <div class="col" class="rules-label">
            <div id="QA-DataOverrideMatrix-Rules">Notes: </div>
            <p>
                Outage.json will always be the system of record and will trump any status
                communicated by M-messages if it's different. This chart illustrates the data rules
                for how the Alerts by SDG&E app will update when new data is received by the system
                for that address. Any desired changes to the system data override rules listed here
                would require programmatic changes on the backend of the system.
            </p>
        </div>
    </ng-container>
</div>